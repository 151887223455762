import React from 'react';
import classes from "./backgroundVideo.module.css";

const BackgroundVideo = ({video}) => {

    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <div

    className={classes.container}
      
    >
      <video
        autoPlay
        loop
        muted
        playsinline="true" 
        disablePictureInPicture="true"
        className={classes.video}
      >
        <source src={video} type="video/mp4" />
        {/* Add additional video sources here for cross-browser compatibility */}
      </video>
    </div>
  );
};

export default BackgroundVideo;