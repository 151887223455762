import React, {useEffect, useState} from 'react'
import styles from "./mediaFooter.module.css";

import {MdTableRestaurant, MdOutlineMenuBook} from 'react-icons/md'
import {AiFillPhone} from 'react-icons/ai'

const MediaFooter = () => {


  const restaurantPhoneNumber = '+353-91-637-530';
  const [scrollStyles, setScrollStyles] = useState({
    transform: 'translateY(0)',
    
  });

  const listenScrollEvent = () => {
    if (window.scrollY > 80) {
      setScrollStyles({
        transform: 'translateY(110%)'
      });
    } else {
      setScrollStyles({
        transform: 'translateY(0)'
      });
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent);
    return () => {
      window.removeEventListener('scroll', listenScrollEvent);
    };
  }, []);


  
  const handleCallRestaurant = () => {
    window.location.href = `tel:${restaurantPhoneNumber}`;
  };
  
  return (
    <div className={styles.container}>
        <div className={styles.wrapper} style={scrollStyles}>
            <div className={styles.btn}>
                <a href="/book-a-table"> <MdTableRestaurant className={styles.icon}/> <p>BOOK A TABLE</p></a>
            </div>
            <div className={styles.btn}>
               <div onClick={handleCallRestaurant} > <AiFillPhone className={styles.icon}/> <p>CALL US</p></div> 
            </div>
            <div className={styles.btn}>
               <a href="/menus"> <MdOutlineMenuBook className={styles.icon}/>  <p>MENUS</p></a>
            </div>
        </div>
    </div>
  )
}

export default MediaFooter