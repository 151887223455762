import React, { useState, useRef, useEffect} from 'react';
import classes from "./valentinesMain.module.css";
import emailjs from '@emailjs/browser'
import axios from 'axios';


const ValentinesMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);
  const imgRef = useRef([]);
  const boutonRef = useRef([]);

  

  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    imgRef.current.forEach((desc) => {
        appearOnScroll.observe(desc);
      });

      boutonRef.current.forEach((desc) => {
        appearOnScroll.observe(desc);
      });

  

  
    return () => {
      appearOnScroll.disconnect();
    };
  }, []);


  






  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        await axios.post('https://connollysapi-n476.onrender.com/email/send', {
          recipient,
          subject,
          text,
          to,
        });
  
        // Clear the form fields after successful submission
        setRecipient('');
        setSubject('');
        setText('');
      
  
        alert('Email sent successfully!');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again later.');
        
      }
    };



  return (

<div className={classes.container}>
    <div className={classes.topDiv}>
    <div className={classes.abso}> <span>VALENTINE'S WEEK @ CONNOLLY'S</span></div>
  <img src={ PF + "shoot (10).jpg"}/>
 
</div>
<div className={classes.middleDiv}>
  <p className={classes.specialP}>Make it a night to remember...</p>
  <div className={classes.hr}>
            <hr/>
            <hr/>

            </div>
  <p>
      Celebrate Valentines 2024 in the perfect venue. Upstairs @ Connolly's for fine-dining and an impressive collection of wines,  or take a trip downstairs for some cosy (candle-lit) drinks by the roaring fire place...
  </p>

  
</div>

<div className={classes.wrapper}>
            <div className={classes.row} ref={(el) => (descRef.current[0] = el)}>
                <div className={classes.imgCont}>
                    <img src={PF + "wed16.jpeg" } />
                </div>
                <div className={classes.txtBox}>
                    <div className={classes.hr}>
                        <hr/>
                    </div>
                    <p className={classes.specialP}>VALETNINE'S @ CONNOLLY'S</p>
                    <p className={classes.desc}>
                   With Connolly's overlooking the Kinvara harbour, it makes for somewhat of a romantic setting. Settling down after a leisurely stroll through the village, for some beautiful food & drink in a relaxing venue.
    
                     </p>
                    <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>FIND US</button></a>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                </div>
            </div>
            <div className={classes.row} >
                <div className={classes.imgCont} id={classes.idOne}  ref={(el) => (imgRef.current[0] = el)} >
                <img src={PF + "NewYearsWine.jpg"} alt="chefs hard at work in the beautiful upstairs kitchen" />
                </div>
                <div className={classes.txtBox} id={classes.idTwo} ref={(el) => (boutonRef.current[0] = el)}>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                    <p className={classes.specialP}> VALENTINE'S WEEK MENU <br/>(14th - 18th)</p>
                    <p className={classes.desc}>
                  For Valentine's week, we have prepared an exclusive dining experience. Using fresh, local & organic produce wherever possible.  
                    </p>
                    <a className={classes.a} href="/menus?valentines2024"> <button className={classes.giftBtn}>VALENTINE'S MENU</button></a>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                    
                </div>
            </div>
          
          
          
        </div>

    
   
 {/* <div style={{backgroundImage: `url('${ PF +  "beauty.webp"}')`}} className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (descRef.current[1] = el)} onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>

      <p className={classes.mess}>For all career inquiries, contact Jason O'Neill for more details. </p>
      
      <form onSubmit={handleSubmit}>
      
      <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />

<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
       
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />

<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />

      <button className={classes.getBtn}>SEND</button>
    </form>
      
  </div> 


  </div>*/}
  </div>
   
  )
}

export default ValentinesMain;