import React from 'react'
import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import ColumnHero from '../../components/columnHero/ColumnHero'
import Navbar2 from '../../components/navbar2/navbar2'
import TopComponent from '../../components/topComponent/TopComponent'
import GalleryFinal from '../../components/galleryFinal/GalleryFinal'
import { useSelector } from 'react-redux'


const GalleryPage = () => {


  const  navStyles={ backgroundColor: "#fff", position: "relative"}


  const images = useSelector((state) => state.website.data.galleryImgs);

 /* const images = [

   
    'wed12.jpeg',

    
    'shoot (30).jpg',
    'wed17.jpeg',

    'shoot (33).jpg',
 
   
    
  
    'food5.jpg',
    'shoot (41).jpg',
    
   
   
    
  
  
   
    'shoot (51).jpg',

   
    'wed1.jpeg',
   
 
    'wed4.jpeg',
    'wed5.jpeg',
    'shoot (13).jpg',
    'wed6.jpeg',
   
    'wed8.jpeg',
    'shoot (50).jpg',
   
    'wed10.jpeg',
    'wed11.jpeg',
    
    'shoot (5).jpg',
    'wed13.jpeg',
    

    'crowd6.jpg',
    'wed16.jpeg',
    'shoot (27).jpg',
   
    'wed18.jpeg',
    'shoot (1).jpg',
   
    'shoot (3).jpg',
    
    
    'shoot (6).jpg',
    
    'shoot (8).jpg',
    'chefstable2.jpg',
   
    'shoot (10).jpg',
    'newbatch7.jpg',
    'shoot (11).jpg',
   
    
    'shoot (14).jpg',
    'shoot (15).jpg',
    'newbatch5.jpg',
    
    'downstairs1.jpg',
    'shoot (21).jpg',
    
    'shoot (23).jpg',
  

   
    'newbatch6.jpg',
    
    'newbatch8.jpeg',
    'shoot (40).jpg',
    'wed12.jpeg',
    'newbatch9.jpeg',
    'shoot (31).jpg',
    'newbatch10.jpeg',


   
    
    
    'bigFish.jpg',
    'fruitmeat.jpg',
    'shoot (49).jpg',
    'shell.jpg',
    'food10.jpeg',
    'wed15.jpeg',
    'food11.jpeg',
    'shoot (42).jpg',
    'food12.jpeg',
   
    'food13.jpeg',
    'food14.jpeg',
    
    'food9.jpg',
    'shoot (12).jpg',
    'food4.jpg',
    'shoot (19).jpg',
    'wed7.jpeg',
    'shoot (16).jpg',
   
   
    'food6.jpg',
   
    'food7.jpg',
    'shoot (46).jpg',
   
   
    
    'food8.jpg',
    'music5.jpg',
    'shoot (2).jpg',
    'music6.jpg',
   
    'crowd1.jpg',
    'shoot (4).jpg',
    'crowd2.jpg',
    
    'crowd4.jpg',

    'crowd5.jpg',
    'shoot (36).jpg',
   
    'crowd7.jpg',
   
   
   'downstairs2.jpg',
   'shoot (17).jpg',
   'downstairs3.jpg',
   'busy1.jpg',
    'chefstable1.jpg',

    'shoot (18).jpg',
    
   
   
    'chefstable4.jpg',
    'wed9.jpeg',
    'upstairs1.jpg',
    'shoot (48).jpg',
   
    
    
    'careers4.jpg',
    'music1.jpg',
    
    'shoot (28).jpg',
    'music2.jpg',
    'decor.png',
   
    'shoot (24).jpg',
    'dessert.png',
    'shoot (25).jpg',
    
    'shoot (9).jpg',
    'niceOutside.png',
    'kinvara-wool-market.jpg',
    'opening.png',
    'shoot (7).jpg',
    'con2.png',
    'shrimp.jpg',
    'shoot (38).jpg',

   
    'winecave1.jpg',
    'winecave3.jpg',
    'shoot (22).jpg',
    'niceOutside.png',
    'careers1.jpg',
    'shoot (20).jpg',
    "U3.png",
    "U9.jpg",
  ]; */

  return (
    <div className="homeContainer">
   
   {/*<Title/> */}
   <TopComponent/>
    <Navbar2 styles2={navStyles}/>
    
    <GalleryFinal images={images}/>
    



<ColumnHero/>

<DisclaimerRow/>

    
    </div>
  )
}

export default GalleryPage