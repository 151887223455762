import React, { useState, useEffect } from 'react';
import styles from "./homeVideo.module.css";
import BackgroundVideo from '../backgroundVideo/BackgroundVideo';
import { useSelector } from 'react-redux';
import ClipLoader from "react-spinners/ClipLoader";

const HomeVideo = () => {
  const reduxData = useSelector((state) => state.website);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const videoUrl = `https://connollysapi-n476.onrender.com/images/${reduxData.data.heroVideo}`;

  useEffect(() => {
    const videoElement = document.createElement('video');
    videoElement.src = videoUrl;
    videoElement.oncanplaythrough = () => setIsVideoLoaded(true);
  }, [videoUrl]);

  return (
    <div className={styles.carouselCont}>
      {isVideoLoaded ? (
        <>
          <BackgroundVideo video={videoUrl} />
          <div className={styles.homeAbso}>
            <p className={styles.homeSpecialP}> {reduxData.data.landingHeroTitle}</p>
          </div>
        </>
      ) : (
        <div className={styles.preloaderContainer}>
          <ClipLoader />
        </div>
      )}
    </div>
  );
};

export default HomeVideo;
