import React, { useEffect, useState } from 'react';
import {  useParams } from 'react-router-dom';
import { useSelector } from 'react-redux'
import { request } from '../../util/fetchAPI';
import Navbar2 from '../../components/navbar2/navbar2';
import TopComponent from '../../components/topComponent/TopComponent';
import ColumnHero from '../../components/columnHero/ColumnHero';
import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow';
import RouteMain from '../../components/routeMain/routeMain';


const RouteDetail = () => {

  const [currentProperty, setCurrentProperty] = useState({});
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [isReady, setIsReady] = useState(false)
  const routeData = useSelector((state) => state.route.data)

  const fetchProperty = async () => {
    const data = await request(`/routes/find/${id}`, 'GET');
    setCurrentProperty(data);
    setIsLoading(false); // Set loading to false once data is fetched
  };





  useEffect(() => {
    setIsReady(true); // Assuming that readiness is independent of currentProperty
  }, []);

  return (


    <div className="homeContainer">

      <TopComponent />
      <Navbar2 />

      <RouteMain currentId={id}/>
      <ColumnHero />

      <DisclaimerRow />

    </div>
  );
};

export default React.memo(RouteDetail);

