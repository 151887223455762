import React from 'react'
import styles from "./TopComponent.module.css"
import { useSelector } from 'react-redux'

const TopComponent = () => {

  const reduxData = useSelector((state) => state.website);



  return (
    <div className={styles.container}>
        <p className={styles.specialP}>{reduxData.data.addressLine}</p>
    </div>
  )
}

export default TopComponent;