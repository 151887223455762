import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: []
 
 }



 export const openingHoursSlice = createSlice({
    name: 'openingHours',
    initialState,
    reducers: {
        getOpeningHoursData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getOpeningHoursData} = openingHoursSlice.actions;

export default openingHoursSlice.reducer;



