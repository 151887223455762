import React, {useEffect, useRef} from 'react'
import styles from "./ourTeamMain.module.css"

const OurTeamMain = () => {



    const descRef = useRef([]);
    
    useEffect(() => {
        const appearOptions = {
          threshold: 0.11,
        };
    
        const appearOnScroll = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(styles.appear);
              observer.unobserve(entry.target);
            }
          });
        }, appearOptions);
    
        descRef.current.forEach((desc) => {
          appearOnScroll.observe(desc);
        });

      
    
      
        return () => {
          appearOnScroll.disconnect();
        };
      }, []);

    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <div className={styles.container}>
         <div   className={styles.carouselCont}>
 <img  src={ PF + "U6.jpg"}/>

   <div className={styles.homeAbso}>
    <p className={styles.homeSpecialP}> OUR TEAM</p>
  </div>  
  </div>
    <div className={styles.topWrap}>
        <p className={styles.specialP}>Meet our Team</p>
        <div className={styles.hr}>
            <hr/>
            <hr/>
        </div>
        <p>Our team is made up of some of the finest talent from across the West Coast of Ireland. Hand-picked for their dedication, reputation and experience.  </p>

        <p>Think you've got what it takes? Click HERE to discover career opportunities.</p>

        </div>

       
        <div className={styles.wrapper} >
         
           



            <div className={styles.profile} ref={(el) => (descRef.current[0] = el)}>
                <div className={styles.imgWrapper}>
                    <img src={PF + "1.jpg"}/>
                </div>
                <div className={styles.textWrapper}>
                <p className={styles.specialP}>Jason O'Neil</p>
                    <h5>Restaurant Director</h5>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto reprehenderit est, id veritatis dolorem eos distinctio aut recusandae iste eveniet! Porro cumque eum architecto. 
                        Consequatur nobis eum doloribus optio in.</p>
                </div>
            </div>

            <div className={styles.profile} ref={(el) => (descRef.current[1] = el)}>
                <div className={styles.imgWrapper}>
                    <img src={PF + "bart.jpg"}/>
                </div>
                <div className={styles.textWrapper}>
                <p className={styles.specialP}>Jason O'Neil</p>
                    <h5>Restaurant Director</h5>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto reprehenderit est, id veritatis dolorem eos distinctio aut recusandae iste eveniet! Porro cumque eum architecto. 
                        Consequatur nobis eum doloribus optio in.</p>
                </div>
            </div>

            <div className={styles.profile} ref={(el) => (descRef.current[2] = el)}>
                <div className={styles.imgWrapper}>
                    <img src={PF + "3.jpg"}/>
                </div>
                <div className={styles.textWrapper}>
                    <p className={styles.specialP}>Jason O'Neil</p>
                    <h5>Restaurant Director</h5>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto reprehenderit est, id veritatis dolorem eos distinctio aut recusandae iste eveniet! Porro cumque eum architecto. 
                        Consequatur nobis eum doloribus optio in.</p>
                </div>
            </div>
            <div className={styles.profile} ref={(el) => (descRef.current[4] = el)}>
                <div className={styles.imgWrapper}>
                    <img src={PF + "4.jpg"}/>
                </div>
                <div className={styles.textWrapper}>
                <p className={styles.specialP}>Jason O'Neil</p>
                    <h5>Restaurant Director</h5>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto reprehenderit est, id veritatis dolorem eos distinctio aut recusandae iste eveniet! Porro cumque eum architecto. 
                        Consequatur nobis eum doloribus optio in.</p>
                </div>
            </div>
            <div className={styles.profile} ref={(el) => (descRef.current[5] = el)}>
                <div className={styles.imgWrapper}>
                    <img src={PF + "5.jpg"}/>
                </div>
                <div className={styles.textWrapper}>
                <p className={styles.specialP}>Jason O'Neil</p>
                    <h5>Restaurant Director</h5>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto reprehenderit est, id veritatis dolorem eos distinctio aut recusandae iste eveniet! Porro cumque eum architecto. 
                        Consequatur nobis eum doloribus optio in.</p>
                </div>
            </div>
            <div className={styles.profile} ref={(el) => (descRef.current[6] = el)}>
                <div className={styles.imgWrapper}>
                    <img src={PF + "6.jpg"}/>
                </div>
                <div className={styles.textWrapper}>
                <p className={styles.specialP}>Jason O'Neil</p>
                    <h5>Restaurant Director</h5>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Iusto reprehenderit est, id veritatis dolorem eos distinctio aut recusandae iste eveniet! Porro cumque eum architecto. 
                        Consequatur nobis eum doloribus optio in.</p>
                </div>
            </div>
                 
            
                
          
        </div>
        <div className={styles.extraDiv}>
            <p className={styles.specialP}> Think you've got what it takes? Click HERE to discover career opportunities.</p>
           <a href="/careers"> <button>CAREERS</button></a>
        </div>

    </div>
  )
}

export default OurTeamMain;