import React from 'react';

const ResDiaryWidget = () => {



  return (
  
    <div id="rd-widget-frame" style={{  margin: 'auto', width: "auto", height: "auto" }}>
  
    </div>
      
      
  );
};

export default ResDiaryWidget;