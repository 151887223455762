import React, { useState } from 'react'
import classes from './login.module.css'
import { useDispatch, useSelector} from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { request } from '../../util/fetchAPI'
import { login } from '../../redux/authSlice'


const Login = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const reduxData = useSelector((state) => state.website)

  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const options = {
        'Content-Type': 'application/json'
      }

      const data = await request('/superUser/login', "POST", options, { email, password })
      dispatch(login(data))
      navigate("/admin-dashboard")

    } catch (error) {
      console.error(error)
      alert("Invalid email or password.");
    }

  }

  return (
    <div style={{ backgroundImage: `url('${PF + "oldbatch7.jpg"}')` }} className={classes.container}>



      <div className={classes.topBar}>
        <div className={classes.section}>
          <img alt="logo" src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.logoImg}`} />
          <h1>Upstairs @ Connolly's</h1>
          <p>Super admin service</p>
        </div>
      </div>

      <div className={classes.wrapper}>

        <h2>Log in</h2>
        <form onSubmit={handleLogin}>
          <input className={classes.titleInput2} type="email" placeholder="Email..." onChange={(e) => setEmail(e.target.value)} />
          <input   className={classes.titleInput2} type="password" placeholder="Password..." onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Sign in</button>
       
        </form>
      </div>




    </div>
  )
}

export default Login