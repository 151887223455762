import React, { useEffect } from 'react'
import styles from "./disclaimerRow.module.css"
import { BsFacebook, BsInstagram, BsTwitter } from 'react-icons/bs';
import { useSelector } from 'react-redux'
import { FaXTwitter } from "react-icons/fa6";
import { Link } from 'react-router-dom';


const DisclaimerRow = () => {

  const reduxData = useSelector((state) => state.website);

  useEffect(() => {
    const script = document.createElement('script');
    script.src =
      'https://www.jscache.com/wejs?wtype=excellent&uniq=855&locationId=7796061&lang=en_US&display_version=2';
    script.async = true;
    script.onload = () => {
      // Script has loaded
      // You can add any script-specific functionality here
    };
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.box} id={styles.iconRow}>

          <div>
            <a target="_blank" href="https://www.tripadvisor.com/Attraction_Review-g551542-d7796061-Reviews-Connolly_s_Bar_Kinvara-Kinvara_County_Galway_Western_Ireland.html">
              <img
                src="https://static.tacdn.com/img2/brand_refresh/Tripadvisor_lockup_horizontal_secondary_registered.svg"
                alt="TripAdvisor"
                className="widEXCIMG"
                id="CDSWIDEXCLOGO"
              />
            </a>
          </div>
          <div className={styles.iconSpan}>
            <span >
              <a href={`${reduxData.data.facebookLink}`} rel="noreferrer" target="_blank">
                <BsFacebook className={styles.icon} />
              </a>

            </span>
            <span>
              <a href={`${reduxData.data.instaLink}`} rel="noreferrer" target="_blank">
                <BsInstagram className={styles.icon} />
              </a>
            </span>
            <span>
              <a href={`${reduxData.data.xLink}`}  className={styles.icon2} rel="noreferrer" target="_blank">
               
                <svg  xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" className="bi bi-twitter-x" viewBox="0 0 16 16">
                  <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z" />
                </svg>

              </a>
            </span>
          
          </div>





        </div>

        <div className={styles.box} id={styles.topBox}>
          <span><a href="#">BACK TO TOP ↑</a></span>
          <span><a href="/book-a-table">NEED A TABLE?</a></span>
        </div>
        <div className={styles.box} id={styles.imgBox}>
          <Link to="/gallery">
            <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.extraLogo2}`} alt='logo' />
          </Link>
        </div>

        <div className={styles.box}>

          <span><a href="/disclaimer">DISCLAIMER</a></span>
          <span><a href="/privacy-policy">PRIVACY POLICY</a></span>
          <span><a href="/terms-and-conditions">TERMS & CONDITIONS</a></span>


        </div>
      </div>
    </div>
  )
}

export default DisclaimerRow