import React, { useEffect, useState } from 'react';
import styles from "./navbar2.module.css"
import Dropdown from '../dropdown/dropdown';
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';



const Navbar2 = ({styles2}) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const reduxData = useSelector((state) => state.website);
  const routeData = useSelector((state) => state.route.data)




  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };




  const history = useNavigate();

  const navigateToPage = (path) => {
      window.scrollTo(0, 0);
      history(path);
  };


  const [currentLinks, setCurrentLinks] = useState([]);




  useEffect(() => {


    let newLinks = [];

    for (const route of routeData) {

    console.log(routeData)

      newLinks.push({title: route.route, link: `/event/${route._id}` })





    }


    console.log(newLinks)

    setCurrentLinks([ ...newLinks])

  }, [routeData])



  return (
 <div className={styles.container}>
      <nav style={styles2} className={styles.navbar}>
        
           
        <a href="/"><img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.logoImg}`} alt="Logo" /></a>
        <div className={`${styles.menu} ${isMenuOpen ? styles.open : ''}`}>
        
          <ul>
          <li><Dropdown title1={"EVENTS"} 
            dropdownObject={[{title: "MUSIC", link: "/music-at-connollys"},  ...currentLinks ]}
           /></li>
            
            <li><a href="/menus">MENUS</a></li>
           
            <li><a href="/contact">CONTACT</a></li>
            <li><a href="/gallery"> GALLERY</a></li>
         
            <li><Dropdown title1={"ABOUT"}  
            dropdownObject={ [ {title: "CAREERS", link: "/careers"}, {title: "CHEF'S TABLE", link: "/chefs-table-experience"}, {title: "WINE CAVE", link: "/wine-experience-room"} ]}
            /></li>
           
            
          </ul>
        </div>
        <div className={styles.hamburger} onClick={toggleMenu}>
          <div className={`${styles.line} ${isMenuOpen ? styles.open : ''}`}></div>
          <div className={`${styles.line} ${isMenuOpen ? styles.open : ''}`}></div>
          <div className={`${styles.line} ${isMenuOpen ? styles.open : ''}`}></div>
        </div>
        <div className={styles.rightBox}>
        <a className={styles.a} href="/book-a-table"> <button className={styles.giftBtn}>BOOK A TABLE</button></a>
        </div>
      </nav>
      </div>
  
  );
};

export default Navbar2;