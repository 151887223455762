import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: {}
 
 }



 export const privacySlice = createSlice({
    name: 'privacy',
    initialState,
    reducers: {
        getPrivacyData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getPrivacyData} = privacySlice.actions;

export default privacySlice.reducer;



