import React, { useState, useRef, useEffect } from 'react';
import classes from "./EventsMain.module.css";
import axios from 'axios';


const EventsMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);
  const imgRef = useRef([]);
  const boutonRef = useRef([]);



  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    imgRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    boutonRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);










  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://connollysapi-n476.onrender.com/email/send', {
        recipient,
        subject,
        text,
        to,
      });

      // Clear the form fields after successful submission
      setRecipient('');
      setSubject('');
      setText('');


      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again later.');

    }
  };



  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}> <span>EVENT BOOKINGS @ CONNOLLY'S</span></div>
        <img src={PF + "shoot (8).jpg"} alt="A view of Connolly's restaurant" />

      </div>
      <div className={classes.middleDiv}>
        <p className={classes.specialP}>The perfect host for your special occasion...</p>
        <div className={classes.hr}>
          <hr />
          <hr />

        </div>
        <p className={classes.desc} id={classes.descId}>
          Situated right on the harbour and at the very centre of Kinvara town,
          Connolly's is a sublime venue and a perfect place for any special occasion. With its homely, yet stylish, decor -
          its combination of gourmet fine-dining delicacies, boutique wine and old favourties -
          we invite you to customise and work with the team @ Connolly's to craft your momentous day, tailored to your preferences.</p>


      </div>

      <div className={classes.wrapper}>
       
        <div className={classes.row} ref={(el) => (descRef.current[0] = el)} >
          <div className={classes.imgCont}>
            <img alt="festive" src={PF + "wed4.jpeg"} />
          </div>
          <div className={classes.txtBox} >
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>SEASONAL EVENTS @ CONNOLLY'S</p>
            <p className={classes.desc}>
              Be it a party, a private gig or even a wedding reception, Connolly's takes bookings for all manner of celebrations.

            </p><p className={classes.desc}>
              If you find that your party size exceeds the allowed number on the booking system, <a href="/contact">contact us</a> to discuss special arrangements.
            </p>
            <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>
            <div className={classes.hr}>
              <hr />
            </div>
          </div>
        </div>
        <div className={classes.row}>
          <div className={classes.imgCont} id={classes.idOne} ref={(el) => (imgRef.current[0] = el)} >
            <img src={PF + "wed18.jpeg"} />
          </div>
          <div className={classes.txtBox} id={classes.idTwo} ref={(el) => (boutonRef.current[0] = el)}>
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>BESPOKE MENUS</p>
            <p className={classes.desc}>
              Our menu seemlessly infuses Irish traditional cuisine with modern fine-dining.
              This allows our culinary pallete to be daring and eclectic, while still maintaing an elegant simplicity.
              In chosing a menu for your event, the scope is wide for both food and drink.</p>
            <p className={classes.desc}><a href="/contact">Send us an email</a> with your menu ideas,
              and as long they are reasonably within the scope of what we currently serve,
              we will be happy to cater to your needs.
            </p>
            <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>MENUS</button></a>
            <div className={classes.hr}>
              <hr />
            </div>

          </div>
        </div>
        <div className={classes.row} ref={(el) => (descRef.current[1] = el)}>
          <div className={classes.imgCont}  >
            <img src={PF + "wed10.jpeg"} />
          </div>
          <div className={classes.txtBox} >
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>WEDDINGS @ CONNOLLY'S</p>
            <p className={classes.desc}>
              Connolly's is becoming a popular destination for wedding receptions and similarly significant events.
              Perhaps it is down to Connolly's long history and significance in the town of Kinvara, or the fact it lends itself to the perfect location for making those special memories.

            </p>
            <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>
            <div className={classes.hr}>
              <hr />
            </div>

          </div>
        </div>


      </div>



      {/* <div style={{backgroundImage: `url('${ PF +  "beauty.webp"}')`}} className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (descRef.current[1] = el)} onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>
      <p className={classes.mess}>For all career inquiries, contact Jason O'Neill for more details. </p>
      <form onSubmit={handleSubmit}>
      <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />
<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />
<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />
      <button className={classes.getBtn}>SEND</button>
    </form>
  </div> 
  </div>*/}
    </div>

  )
}

export default EventsMain;