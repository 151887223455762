import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import authSlice from "./authSlice";
import websiteSlice from "./websiteSlice";
import menusSlice from "./menusSlice";
import routeSlice from "./routeSlice";
import privacySlice from "./privacySlice";
import termsSlice from "./termsSlice";
import openingHoursSlice from "./openingHoursSlice";
import musicSlice from "./musicSlice";


const persistConfig = {
    key: "root",
    version: 1,
    storage,
};

const reducers = combineReducers({
    auth: authSlice,
    website:  websiteSlice,
    menus: menusSlice,
    route: routeSlice,
    privacy: privacySlice,
    terms: termsSlice,
    openingHours: openingHoursSlice,
    music: musicSlice
})

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }),
});

export let persistor = persistStore(store);