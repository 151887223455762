import React, { useState, useEffect, useRef } from 'react'
import classes from "./adminMusic.module.css"
import { Link, useNavigate } from 'react-router-dom';
import { AiOutlineFileImage, AiOutlineClose } from 'react-icons/ai'
import { useDispatch, useSelector } from 'react-redux'
import { register } from '../../../redux/authSlice'
import Datetime from 'react-datetime';
import moment from "moment";
import { logout } from '../../../redux/authSlice'
import { request } from '../../../util/fetchAPI'
import Modal from 'react-modal';



const AdminMusic = () => {


  const [photo, setPhoto] = useState("")
  const [photo2, setPhoto2] = useState("")
  const [photo3, setPhoto3] = useState("")
  const { user, token } = useSelector((state) => state.auth)
  const [state, setState] = useState({});
  const [state2, setState2] = useState({});
  const [state3, setState3] = useState(user);
  const [allEvents, setAllEvents] = useState([]);
  const [filteredEvents, setFilteredEvents] = useState([]);
  const [remainingEvents, setRemainingEvents] = useState([]);
  const [showForm2, setShowForm2] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [showForm3, setShowForm3] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [newPassword, setNewPassword] = useState(undefined)
  const [tokenValid, setTokenValid] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const logoutTimeoutRef = useRef(null);
  const [newDateTime, setNewDateTime] = useState()
  const [editDateTime, setEditDateTime] = useState("");
  const [sortedEvents, setSortedEvents] = useState([]);

  useEffect(() => {
    setEditDateTime(moment(state2?.datetime))

  }, [state2]);



  console.log(useSelector((state) => state.auth));

  const initialFormState = {
    artist: "",
    date: "",
    time: "",
    artistLink: "",
    desc: "",
  };

  useEffect(() => {
    const fetchAllEvents = async () => {
      const data = await request(`/event/getAll`, 'GET');
      setAllEvents(data);
    };
    fetchAllEvents();

  }, []);






  useEffect(() => {
    filterEvents(allEvents);

  }, [allEvents]);








  useEffect(() => {



    function sortEventsByDatetime(eventsArray) {
      const sortedEvents = eventsArray.slice().sort((eventA, eventB) => {
        const datetimeA = new Date(eventA.datetime);
        const datetimeB = new Date(eventB.datetime);

        return datetimeA - datetimeB;
      });

      return sortedEvents;
    }

    let sortedList = sortEventsByDatetime(remainingEvents);

    setSortedEvents(sortedList)






  }, [remainingEvents]);



  useEffect(() => {
    // Start the setTimeout when the component mounts
    logoutTimeoutRef.current = setTimeout(() => {
      // This function will be called after the timeout
      // Replace the below line with your actual logout logic
      console.log('User is logged out!');

      // Call the logout function when the timeout completes
      dispatch(logout())
    }, 300000000000000); // Replace 5000 with the desired timeout duration (in milliseconds)

    // Clean up the timer when the component unmounts or when the logout action occurs
    return () => {
      clearTimeout(logoutTimeoutRef.current);
    };
  }, []);




  const handleSelectedEvent = async (id) => {
    const data = await request(`/event/find/${id}`, "GET")
    setEditDateTime("");
    setSelectedEvent(data)
    setState2(data);

  }

  const handleState = (e) => {
    const { name, type, value, checked } = e.target;
    const newValue = value;
    setState({ ...state, [name]: newValue })
  }




  const handleState2 = (e) => {
    const { name, type, value, checked } = e.target;
    const newValue = type === "checkbox" ? checked : value;
    setState2({ ...state2, [name]: newValue })


  }





  const handleState3 = (e) => {
    setState3(prev => {
      return { ...prev, [e.target.name]: e.target.value }
    })

  }




  const handleNewPassword = (e) => {
    setNewPassword(e.target.value)
  }

  const handleCloseForm = () => {
    setShowForm2(false)
    setShowForm(false)
    setSelectedEvent({});
    setState2({});
    setPhoto2("")

  }



  const handleListProperty = async (e) => {
    e.preventDefault();

    let filename1 = "";
    if (photo) {
      const formData = new FormData();

      filename1 = crypto.randomUUID() + photo.name;
      formData.append("filename", filename1);
      formData.append("image", photo);

      await request('/upload/image', "POST", {}, formData, true);
    }
    try {
      const options = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      };

      const requestData = {
        ...state,

        img: filename1,
        datetime: newDateTime

      };

      const data = await request('/event/super', 'POST', options, requestData);



      const fetchAllEvents = async () => {
        const data = await request(`/event/getAll`, 'GET');
        setAllEvents(data);
      };
      fetchAllEvents();

      setState({
        artist: "",
        time: "",
        date: "",
        artistLink: "",
        desc: ""
      });

      setEditDateTime("");


      setPhoto("");

    } catch (error) {

      alert("WARNING: Your authentication token has expired. Please sign in again.");

    }
  };





  const filterEvents = (events) => {


    let filteredEvents = [];
    let remainingEvents = [];


    const now = new Date();

    events.forEach(event => {
      const eventDatetime = new Date(event.datetime);

      if (eventDatetime <= now) {
        filteredEvents.push(event);
      } else {
        remainingEvents.push(event);
      }
    });


    setRemainingEvents(remainingEvents)
    setFilteredEvents(filteredEvents)

  }











  const handleListProperty2 = async (e) => {
    e.preventDefault();
    let filename = null;
    if (photo2) {
      const formData = new FormData()
      filename = crypto.randomUUID() + photo2.name
      formData.append("filename", filename)
      formData.append("image", photo2)
      await request('/upload/image', "POST", {}, formData, true)
    }
    else {
      filename = selectedEvent.img
    }


    try {
      const options = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }


      const data = await request(`/event/${selectedEvent._id}/super`, 'PUT', options, {
        ...state2,

        img: filename,
        datetime: editDateTime
      });
      const fetchAllEvents = async () => {
        const data = await request(`/event/getAll`, 'GET');
        setAllEvents(data);
        alert(`Event successfully updated.`)



      };
      fetchAllEvents();
      handleCloseForm()

      setEditDateTime(null);










    } catch (error) {
      console.error(error)
    }

  }







  const deleteProperty = async () => {
    try {
      await request(`/event/${selectedEvent._id}/super`, 'DELETE', { Authorization: `Bearer ${token}` });
      const fetchAllEvents = async () => {
        const data = await request(`/event/getAll`, 'GET');
        setAllEvents(data);
      };
      fetchAllEvents();

    } catch (error) {
      console.log(error);
    }
  };

  const confirmDelete = () => {

    deleteProperty();


    setShowForm2(false);


  };








  const handleSubmit = async (e) => {

    e.preventDefault()

    try {

      let filename = null
      if (photo3 !== undefined) {
        const formData = new FormData()
        filename = crypto.randomUUID() + photo3.name
        formData.append("filename", filename)
        formData.append('image', photo3)

        await request('/upload/image', "POST", {}, formData, true)
      } else {
        filename = user.profileImg
      }

      const options = {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      }


      if (newPassword !== undefined) {

        const data = await request(`/auth/user/${user._id}`, "PUT", options, { ...state3, profileImg: filename, password: newPassword })
        dispatch(register(data))

        console.log(data)

      } else {
        const data = await request(`/auth/user/${user._id}`, "PUT", options, { ...state3, profileImg: filename })
        dispatch(register(data))




      }





    } catch (error) {
      console.error(error)
      alert(error.msg)
    }
  }


  function formatEventDatetime(datetimeString) {
    const eventDate = new Date(datetimeString);

    const options = {
      weekday: 'long',        // Display the full weekday name
      day: 'numeric',         // Display the day of the month
      month: 'long',         // Display the full month name
      year: 'numeric',        // Display the year
      hour: 'numeric',        // Display the hour in 12-hour format
      minute: 'numeric',      // Display the minutes
      hour12: true,           // Use 12-hour format
    };

    const formattedDatetime = eventDate.toLocaleString('en-US', options);
    return formattedDatetime;
  }


  return (
  <div className={classes.container}>

        <h1>Connolly's Music Admin</h1>



        <h2>Events List</h2>

        <div className={classes.properties}>

          {sortedEvents.map((event) => (
            <div key={event._id} className={classes.property}>
              <div className={classes.imgContainer}>
                <img src={`https://connollysapi-n476.onrender.com/images/${event.img}`} alt="" />
              </div>

              <div className={classes.details}>
                <h3 className={classes.propName}>{event.artist}</h3>
                <p>{formatEventDatetime(event.datetime)}</p>


                <a href={event.artistLink}>Artist Page Link</a>

                <button className={classes.btnEdit} onClick={() => {
                  setShowForm(true);
                  handleSelectedEvent(event._id)
                }}>Edit</button>

                <button className={classes.btnDelete} onClick={() => {
                  setShowForm2(true);
                  handleSelectedEvent(event._id)
                }}>Delete</button>





              </div>
            </div>
          ))}







          {
            showForm && (



              <Modal
                isOpen={showForm}
                onRequestClose={() => setShowForm(false)}
                contentLabel="Delete User Modal"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: "999999999999999999999999999999999999",
                  },
                  content: {
                    width: '70%',
                    height: "80vh",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    backgroundColor: "#2d2c2c",
                    border: "0",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch", // For iOS Safari
                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                  },
                }}
              >

                <div className={classes.listPropertyForm} onClick={(e) => e.stopPropagation()} >
                  <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                    <h2>Edit Event</h2>
                    <form onSubmit={handleListProperty2}>




                      <div className={classes.inputs}>
                        <input type="text" value={state2.artist} placeholder='Artist...' name='artist' onChange={handleState2} />
                        <textarea className={classes.scrip} type="text" value={state2.desc} placeholder='Description...' name='desc' onChange={handleState2} />

                        <input type="text" value={state2?.artistLink} placeholder='Artist website or social media' name='artistLink' onChange={handleState2} />
                        <div>
                          <label className={classes.garden}>Date & Time:
                            <Datetime value={editDateTime}
                              className={classes.datetime}
                              onChange={date => setEditDateTime(date)}
                              onClick={(e) => e.stopPropagation()}
                              inputProps={{ style: { width: '100%', display: 'inline-block' } }}


                            />

                          </label>
                        </div>


                      </div>




                      <label className={classes.btnUpload} htmlFor="photo2">Main Image</label>
                      <input
                        id="photo2"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={(e) => setPhoto2(e.target.files[0])}
                      />

                      {photo2 === "" ? (
                        <img src={`https://connollysapi-n476.onrender.com/images/${selectedEvent?.img}`} style={{ width: '100px', height: '100px', objectFit: "cover" }} alt="Uploaded" />
                      ) : (
                        <img src={URL.createObjectURL(photo2)} style={{ width: '100px', height: '100px', objectFit: "cover" }} alt="Uploaded" />
                      )}



                      <button className={classes.formButton2} type="submit">Update Event</button>
                    </form>

                    <AiOutlineClose onClick={() => {
                      setShowForm(false)
                    }} className={classes.removeIcon} />

                  </div>
                </div>
              </Modal>


            )
          }
          {showForm2 && (
            <Modal
              isOpen={showForm2}
              onRequestClose={() => setShowForm2(false)}
              contentLabel="Delete User Modal"
              style={{
                overlay: {
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: "999999999999999999999999999999999999",
                },
                content: {
                  width: '80%',
                  height: '150px',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  color: 'white',
                  backgroundColor: "#2d2c2c",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  justifyContent: "center",
                  gap: "2rem",
                  border: "0",
                  borderRadius: "20px",
                  boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch", // For iOS Safari
                  scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                  scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                },
              }}
            >

              <h2 styles={{fontWeight: "200"}}>Are you sure you want to delete this Event?</h2>
              <AiOutlineClose onClick={() => {
                setShowForm2(false)
              }} className={classes.removeIcon} />
              <div className={classes.btnDels}>
                <button className={classes.formButton2} onClick={confirmDelete}>Delete</button>
                <button className={classes.formButton2} onClick={() => setShowForm2(false)}>Cancel</button>
              </div>
            </Modal>
          )}





        </div>

        <h2>Archived Events</h2>

        <div className={classes.properties}>


          {filteredEvents.map((event) => (
            <div key={event._id} className={classes.property}>
              <div className={classes.imgContainer}>
                <img src={`https://connollysapi-n476.onrender.com/images/${event.img}`} alt="" />
              </div>

              <div className={classes.details}>
                <h3 className={classes.propName}>{event.artist}</h3>
                <p>{formatEventDatetime(event.datetime)}</p>

                <a href={event.artistLink}>Artist Page Link</a>


                <button className={classes.btnEdit} onClick={() => {
                  setShowForm(true);
                  handleSelectedEvent(event._id)
                }}>Edit</button>

                <button className={classes.btnDelete} onClick={() => {
                  setShowForm2(true);
                  handleSelectedEvent(event._id)
                }}>Delete</button>


              </div>
            </div>
          ))}



        </div>
        <div className={classes.listPropertyForm}  >
          <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
            <h2>New Event</h2>
            <form onSubmit={handleListProperty}>

              <div className={classes.inputs}>
                <input type="text" value={state.artist} placeholder='Artist...' id="artistCreate" name='artist' onChange={handleState} />


                <input type="text" value={state.artistLink} id="artistLinkCreate" placeholder='Artist website or social media' name='artistLink' onChange={handleState} />
                <div className={classes.garden}>
                  <label>Date & Time:
                    <Datetime value={newDateTime} className={classes.datetime} onChange={date => setNewDateTime(date)} onClick={(e) => e.stopPropagation()} inputProps={{ style: { width: '90%', display: 'inline-block', color: "#fff", backgroundColor: "#222", fontSize: "22px" } }} />
                  </label>
                </div>
                <textarea value={state.desc} className={classes.descrip} id="descCreate" placeholder='Description...' name='desc' onChange={handleState} />
              </div>








              <label className={classes.btnUpload} htmlFor="photo">Add Image </label>
              <input
                id="photo"
                type="file"
                style={{ display: 'none' }}
                onChange={(e) => setPhoto(e.target.files[0])}
              />


              {photo && <img src={URL.createObjectURL(photo)} style={{ width: "200px", height: "150px", objectFit: "cover", margin: "2vh 0" }} id="imgCreate" alt="Uploaded" />}










              <button className={classes.formButton2} type="submit">List Event</button>

            </form>




            {showForm3 && (


              <Modal
                isOpen={showForm3}
                onRequestClose={() => setShowForm3(false)}
                contentLabel="Delete User Modal"
                style={{
                  overlay: {
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: "999999999999999999999999999999999999",
                  },
                  content: {
                    width: '85%',
                    height: '80vh',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    color: 'white',
                    backgroundColor: "#2d2c2c",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    gap: "2rem",
                    border: "0",
                    borderRadius: "20px",
                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                    overflow: "auto",
                    WebkitOverflowScrolling: "touch", // For iOS Safari
                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                  },
                }}
              >





                <div className={classes.listPropertyForm}  >
                  <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                    <h2>Edit User</h2>
                    <form onSubmit={handleSubmit}>
                      <input type="text" name="username" value={state3.username} placeholder='Username' onChange={handleState3} required />
                      <input type="email" name="email" value={state3.email} placeholder='Email' onChange={handleState3} required />
                      <textarea value={state3.desc} className={classes.descrip} name="desc" placeholder='New description' onChange={handleState3} />
                      <input type="password" className={classes.newPass} placeholder='newPassword' onChange={handleNewPassword} />


                      <input id="photo3" type="file" style={{ display: 'none' }} onChange={(e) => setPhoto3(e.target.files[0])} />
                      <label className={classes.btnUpload} htmlFor="photo3">Upload photo </label>
                      <div>
                        {photo3 ? <img className={classes.userImg} src={URL.createObjectURL(photo3)} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} /> :
                          <img className={classes.userImg} src={`https://connollysapi-n476.onrender.com/images/${user?.profileImg}`} style={{ width: "60px", height: "60px", borderRadius: "50%" }} alt="profile" />}
                      </div>

                      <p>Please enter your CURRENT PASSWORD to make any changes to your profile, including creating a new password.</p>
                      <input type="password" name="password" value={state3.password} placeholder='currentPassword' onChange={handleState3} />

                      <button className={classes.formButton2} type="submit">UPDATE</button>

                    </form>
                    <AiOutlineClose onClick={() => { setShowForm3(false) }} className={classes.removeIcon} />
                  </div>
                </div>
              </Modal>)
            }

          </div>

         
        </div>

      </div>
    

  )
}

export default AdminMusic