import React, {useEffect, useRef} from 'react'
import classes from "./galleryFinal.module.css"

const GalleryFinal = ({ images }) => {


    const descRef = useRef([]);
    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
    
   


    useEffect(() => {

      if(images.length > 0) {
        const appearOptions = {
          threshold: 0.15,
        };
    
        const appearOnScroll = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(classes.appear);
              observer.unobserve(entry.target);
            }
          });
        }, appearOptions);
    
        descRef.current.forEach((desc) => {
          appearOnScroll.observe(desc);
        });
      
        return () => {
          appearOnScroll.disconnect();
        };

      }


      }, [images]);
  

  
  return (
    <div className={classes.container}>
        <div className={classes.wrapper}>
        {images.map((image, index) => (
          <div className={classes.imgCont} ref={(el) => (descRef.current[index] = el)} key={index}>
            <img src={`https://connollysapi-n476.onrender.com/images/${image}` } alt={`Image ${index}`} />
          </div>
        ))}
            
        </div>
    </div>
  )
}

export default GalleryFinal;