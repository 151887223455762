import React, { useState, useRef, useEffect} from 'react';
import classes from "./festivalMain.module.css";
import emailjs from '@emailjs/browser'
import axios from 'axios';


const FestivalMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);
  const imgRef = useRef([]);
  const boutonRef = useRef([]);

  

  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    imgRef.current.forEach((desc) => {
        appearOnScroll.observe(desc);
      });

      boutonRef.current.forEach((desc) => {
        appearOnScroll.observe(desc);
      });

  

  
    return () => {
      appearOnScroll.disconnect();
    };
  }, []);


  






  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        await axios.post('https://connollysapi-n476.onrender.com/email/send', {
          recipient,
          subject,
          text,
          to,
        });
  
        // Clear the form fields after successful submission
        setRecipient('');
        setSubject('');
        setText('');
      
  
        alert('Email sent successfully!');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again later.');
        
      }
    };



  return (

<div className={classes.container}>
    <div className={classes.topDiv}>
    <div className={classes.abso}> <span>CRUINNIÚ FESTIVAL @ CONNOLLY'S</span></div>
  <img src={PF + "dunguaire.jpg"}/>
 
</div>
<div className={classes.middleDiv}>
  <p className={classes.specialP}>Cruinniú na mBád Festival</p>
  <div className={classes.hr}>
            <hr/>
            <hr/>

            </div>
  <p>
  The year 2023 commemorates the 43rd anniversary of the Kinvara's Cruinniú na mBád Festival, scheduled to occur from the 11th to the 13th of August. This event honors the enduring practice of sailing the captivating Galway Hookers, and during this remarkable regatta, the renowned turf boats grace the waters. The picturesque harbor village of Kinvara becomes a hub of vibrant activity with spirited gatherings featuring prominent figures from the realm of Irish traditional music and vocalists.</p>

  
</div>

<div className={classes.wrapper}>
            <div className={classes.row} ref={(el) => (descRef.current[0] = el)}>
                <div className={classes.imgCont}>
                    <img src={PF + "U10.png"} />
                </div>
                <div className={classes.txtBox}>
                    <div className={classes.hr}>
                        <hr/>
                    </div>
                    <p className={classes.specialP}>CRUINNIÚ @ CONNOLLY'S</p>
                    <p className={classes.desc}>
                   With Connolly's overlooking the Kinvara harbour, it has  naturally  become a hub and rest point for those visting Kinvara for Cruinniú. 
    
                     </p><p className={classes.desc}> 
                       We expect those visiting for Cruinniú Festival 2023 will be happy to see Connolly's Pub alive and humming, with all it's new features and additions.
                    </p>
                    <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>FIND US</button></a>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                </div>
            </div>
            <div className={classes.row} >
                <div className={classes.imgCont} id={classes.idOne}  ref={(el) => (imgRef.current[0] = el)} >
                <img src={PF + "chefstable2.jpg"} />
                </div>
                <div className={classes.txtBox} id={classes.idTwo} ref={(el) => (boutonRef.current[0] = el)}>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                    <p className={classes.specialP}>MENUS</p>
                    <p className={classes.desc}>
                   In celebration of the festival, we have prepared an exclusive dining experience. With flavours and ingredients that reflect Kinvara's history and fine local produce. 
                    </p>
                    <a className={classes.a} href="/menus?festival"> <button className={classes.giftBtn}>CRUINNIÚ MENU</button></a>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                    
                </div>
            </div>
            <div className={classes.row} ref={(el) => (descRef.current[1] = el)}>
                <div className={classes.imgCont}>
                    <img src={PF + "niceOutside.png"} />
                </div>
                <div className={classes.txtBox}>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                    <p className={classes.specialP}>MUSIC @ CONNOLLY'S</p>
                    <p className={classes.desc}>
                    As per tradition, Connolly's will play host to renowned and talented Irish Music Artists to celebrate Cruinniú Festival 2023 and contribute to the sounds and sights of the humming Kinvara Harbour.

                    </p>
                    <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>WHAT'S ON?</button></a>
                    <div className={classes.hr}>
                        <hr/> 
                    </div>
                    
                </div>
            </div>
          
          
        </div>

    
   
 {/* <div style={{backgroundImage: `url('${ PF +  "beauty.webp"}')`}} className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (descRef.current[1] = el)} onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>

      <p className={classes.mess}>For all career inquiries, contact Jason O'Neill for more details. </p>
      
      <form onSubmit={handleSubmit}>
      
      <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />

<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
       
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />

<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />

      <button className={classes.getBtn}>SEND</button>
    </form>
      
  </div> 


  </div>*/}
  </div>
   
  )
}

export default FestivalMain;