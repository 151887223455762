import React, { useRef, useEffect } from 'react';
import classes from "./kitchenMain.module.css";
import { useSelector } from 'react-redux'



const KitchenMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const descRef = useRef([]);


  const reduxData = useSelector((state) => state.website);



  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);











  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}> <span>{reduxData.data.chefsTableTitle}</span></div>
        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.chefsTableTopImg}`} alt="Chefs hard at work in Upstairs @ Connolly's kitchen" />

      </div>
      <div className={classes.middleDiv}>

        <p className={classes.specialP}>{reduxData.data.chefsTableDescTitle}</p>

        <div className={classes.hr}>
          <hr />
          <hr />
        </div>

        <p>
          {reduxData.data.chefsTableDesc}
        </p>

      </div>

      <div className={classes.photosDiv} ref={(el) => (descRef.current[0] = el)}>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.chefsTableImg1}`} alt="Dinner is served. Wine and a beautiful dish." />
        </div>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.chefsTableImg2}`} alt="Jason O'Neill serving perfectly cooked food" />
        </div>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.chefsTableImg3}`} alt="A view of the kitchen from the customer's perspective" />
        </div>
      </div>





    </div>

  )
}

export default KitchenMain;