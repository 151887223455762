import React, { useEffect, useState } from 'react'
import classes from "./adminMenuEdit.module.css"
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { request } from '../../../util/fetchAPI'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";
import Modal from 'react-modal';
import ClipLoader from "react-spinners/ClipLoader";


const AdminMenuEdit = ({ property, closeModal, fetchFunction, currentUser }) => {

    const { token, user } = useSelector((state) => state.auth)
    const { id } = useParams()
    const navigate = useNavigate()


    const [currentEditRoute, setCurrentEditRoute] = useState("")
    const [currentEditTitle, setCurrentEditTitle] = useState("")
    const [currentWord, setCurrentWord] = useState("")
    const [currentWord2, setCurrentWord2] = useState("")
    const [currentWord3, setCurrentWord3] = useState("")
    const [currentType, setCurrentType] = useState("")
    const [menuItems, setMenuItems] = useState([])








    useEffect(() => {

        console.log("Running")

        setCurrentEditTitle(property.title)
        setCurrentEditRoute(property.route)
        setMenuItems(property.contents)



    }, [property]);














    const PF = process.env.REACT_APP_PUBLIC_FOLDER;

















    const handleEditMenu = async (e) => {

        setPreloader(true)



        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;


            data = await request(`/menus/${property?._id}/${currentUser}`, 'PUT', options, {
                title: currentEditTitle,
                route: currentEditRoute,
                contents: menuItems

            })

            fetchFunction();
            setPreloader(false)
            alert("Your MENU has been successfully updated!")
            closeModal();

        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");
            setPreloader(false)

        }

    }


    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(menuItems);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setMenuItems(updatedItems);
    };


    const deleteMenuItem = (index) => {
        const items = [...menuItems];
        items.splice(index, 1);
        setMenuItems(items);
    };



    const handleTypeChange = (e) => {
        setCurrentType(e.target.value);

    };

    const handleTitle = (e) => {
        setCurrentEditTitle(e.target.value)
    }


    const handleRoute = (e) => {
        const newRoute = e.target.value;
        console.log(newRoute);
        const containsNumbers = /\d/.test(newRoute); // Regular expression to check for numbers
        const containsNonAlphabetic = /[^a-zA-Z]/.test(newRoute); // Regular expression to check for non-alphabetic characters
    
        if (!newRoute.includes(" ") && newRoute.length <= 11 && newRoute !== "" && !containsNumbers && !containsNonAlphabetic) {
            // Check if the input meets the conditions
            setCurrentEditRoute(newRoute);
        } else {
            // Handle the case where the input does not meet the conditions
            if (newRoute.includes(" ")) {
                alert("Route cannot contain space characters");
            }
            if (newRoute.length > 11) {
                alert("Route cannot exceed 11 characters");
            }
            if (newRoute === "") {
                alert("Route cannot be empty!");
            }
            if (containsNumbers) {
                alert("Route cannot contain numbers");
            }
            if (containsNonAlphabetic) {
                alert("Route can only contain alphabetic characters");
            }
            // Optionally clear the route state
            // setCurrentEditRoute("");
        }
    }
    

    const handleAddItem = () => {


        let newItem = {}

        if (currentType !== "") {


            if (currentType === "WineItem" || currentType === "Dish") {

                newItem = {
                    elementType: currentType,
                    content: [{ contentString: currentWord }, { contentString: currentWord2 }, { contentString: currentWord3 }]
                }

            } else {

                newItem = {
                    elementType: currentType,
                    content: [{ contentString: currentWord }]
                }

            }



            setMenuItems([...menuItems, newItem]);

            setCurrentWord("");
            setCurrentWord2("");
            setCurrentWord3("")
            setCurrentType("");
        } else {
            alert("You must set an element type")
        }


    }


    const handleWord = (e) => {
        setCurrentWord(e.target.value)
    }
    const handleWord2 = (e) => {
        setCurrentWord2(e.target.value)
    }
    const handleWord3 = (e) => {
        setCurrentWord3(e.target.value)
    }

    const [showFormPreview, setShowFormPreview] = useState(false)




    const handleEditCurrentContentString = (index, objectIndex, e) => {

        const newMenuItems = [...menuItems];


        newMenuItems[index].content[objectIndex].contentString = e.target.value;
        setMenuItems(newMenuItems);



    }


    
    const [open, setOpen] = useState("")



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }

    
    const [preloader, setPreloader] = useState(false)


    const [color, setColor] = useState("#ffffff");



    const override = {
        display: "block",
        margin: "10vh 0",
        borderColor: "red",


    };







    return (



<>
{preloader ? (
                <div style={{width: "auto"}}>
                    <ClipLoader
                        color={color}
                        loading={true}
                        cssOverride={override}
                        size={150}
                        aria-label="Loading Spinner"
                        data-testid="loader"
                    />
                </div>
            ) : ( 

                <div className={classes.makeWrapper}>

                <h2>Edit Menu</h2>
    
    
    
    
                <label className={classes.classicLabel}>
    
                    Menu Title
    
                    <input
                        className={classes.titleInput}
                        type="text"
                        placeholder="Menu Title..."
                        name="title"
                        value={currentEditTitle || ""}
                        onChange={handleTitle}
                    />
    
                </label>
    
                <label className={classes.classicLabel}>
    
                    URL Endpoint
    
    
                    <input
                        className={classes.titleInput2}
                        type="text"
                        placeholder="URL Endpoint"
                        name="title"
                        value={currentEditRoute || ""}
                        onChange={handleRoute}
                    />
    
                </label>
    
                <button className={classes.uniButton} onClick={() => {setShowFormPreview(true)}}>See Preview</button>
    
    
                <div className={classes.keywordDiv}>
    
                <h2>ADD MENU ITEM</h2>
    
                    <div className={classes.typeSelection}>
                        <label>
                            Select Type:
                            <select  className={classes.titleInput2} value={currentType} onChange={handleTypeChange}>
                                <option value="">Select an option</option> {/* Non-option */}
                                <option value="CategoryTitle">CategoryTitle</option>
                                <option value="Dish">Dish</option>
                                <option value="Message">Message</option>
                                <option value="WineItem">WineItem</option>
                                <option value="Line">Line</option>
                                <option value="Break">Break</option>
                            </select>
                        </label>
                    </div>
    
    
                    {
                        currentType === "WineItem" || currentType === "Dish" ?
    
    
                            (
    
                                <div className={classes.contentInputs}>
    
    
                                    <input
                                        className={classes.titleInput2}
                                        type="text"
                                        placeholder="Name"
                                        name="keyword"
                                        value={currentWord || ""}
                                        onChange={handleWord}
                                    />
    
    
                                    <input
                                        className={classes.titleInput2}
                                        type="text"
                                        placeholder="Description"
                                        name="keyword2"
                                        value={currentWord2 || ""}
                                        onChange={handleWord2}
                                    />
    
    
                                    <input
                                        className={classes.titleInput2}
                                        type="text"
                                        placeholder="Price"
                                        name="keyword3"
                                        value={currentWord3 || ""}
                                        onChange={handleWord3}
                                    />
    
                                </div>
    
                            ) : currentType === "Line" || currentType === "Break" ? (
                                <>
    
                                </>
    
    
    
                            ) : (
                                <div className={classes.contentInputs}>
                                    <input
                                        className={classes.titleInput2}
                                        type="text"
                                        placeholder="Keyword..."
                                        name="keyword"
                                        value={currentWord || ""}
                                        onChange={handleWord}
                                    />
                                </div>
                            )
                    }
    
    
    
    
    
    
                    <div className={classes.plusButtonDiv}>
                        <IoMdAddCircle onClick={handleAddItem} className={classes.plusButton} />
    
                    </div>
                </div>
                <DragDropContext onDragEnd={onDragEnd}  onBeforeDragStart={() => {setOpen("")}}>
                    <Droppable droppableId="menuItems">
                        {(provided) => (
                            <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                {menuItems?.map((item, index) => (
                                    <Draggable key={index} draggableId={`item-${index}`} index={index} 
                                    
                                    >
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.keyword}
                                            >
    
    
                                                <AiOutlineClose onClick={() => {
                                                    deleteMenuItem(index)
                                                }} className={classes.removeIcon} />
                                                 <p className={classes.open} onClick={() => { handleOpen(`box-${index}`) }}><span>{item.elementType}</span>
                                                 {item.content[0].contentString !== "" && item.content[0].contentString !== undefined && `: ${item.content[0].contentString}`} </p>
    
                                                 <div className={classes.hiddenBox} style={open === `box-${index}` ? { height: "auto", opacity: "1", zIndex: "999", maxHeight: "4000px", paddingTop: "2vh" } : { maxHeight: "0", zIndex: "-1", opacity: "0" }}>
                                                    {item.elementType !== "Line" && item.elementType !== "Break" && item.content.map((object, objectIndex) => (
                                                        <textarea
                                                            className={classes.titleInput4}
                                                            type="text"
                                                            placeholder= "Text..." 
                                                            value={object.contentString || ""}
                                                            onChange={(e) => handleEditCurrentContentString(index, objectIndex, e)}
                                                        />
                                                    ))}
                                                </div>
                                              
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
    
    
                <button className={classes.formButton2} onClick={handleEditMenu}>Update Menu</button>
    
    
    
    
    
                <Modal
                    isOpen={showFormPreview}
                    onRequestClose={() => setShowFormPreview(false)}
                    contentLabel="Preview Menu Modal"
                    className="modal"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: "999999999999999999999999999999999999",
                        },
                        content: {
                            width: '90%',
                            height: 'auto',
                            maxHeight: '80vh',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: "#f3c7c7",
                            border: "0",
                            borderRadius: "20px",
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch", // For iOS Safari
                            scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                            scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                        },
                    }}
                >
                    <div className={classes.menuContainer}>
                        <AiOutlineClose onClick={() => {
                            setShowFormPreview(false)
                        }} className={classes.removeIcon} />
                       
    
                   
              
    
    
    
    
                      <p className={classes.specialP}>{currentEditTitle !== "" && currentEditTitle !== undefined && currentEditTitle} </p>
    
    
                      {menuItems.map((object, index) => (
    
    
                        <>
                          {object.elementType === "Title" && (
                            <div className={classes.specialP}>{object.content[0].contentString}</div>
    
    
                          )}
    
    
    
                          {object.elementType === "Dish" && (
                            <div className={classes.dish}>
                              <p className={classes.specialTitle}> {object.content[0].contentString}</p>
                              <p>{object.content[1].contentString}</p>
                              <span>{object.content[2].contentString}</span>
                            </div>
    
                          )}
    
    
                          {object.elementType === "WineItem" && (
                            <div className={classes.dish}>
                              <p className={classes.specialTitle}> {object.content[0].contentString}</p>
                              <p>{object.content[1].contentString}</p>
                              <span>{object.content[2].contentString}</span>
                            </div>
    
                          )}
    
    
                          {object.elementType === "Line" && (
                            <div className={classes.hr}>
                              <hr />
    
    
                            </div>
    
                          )}
    
    
                          {object.elementType === "Break" && (
                            <img className={classes.seperator} src={PF + "line.png"} alt="paragraph seperator" />
    
                          )}
    
    
                          {object.elementType === "CategoryTitle" && (
                            <p className={classes.categoryTitle}>{object.content[0].contentString}</p>
    
                          )}
    
    
                          {object.elementType === "Message" && (
                            <p className={classes.message}>{object.content[0].contentString}</p>
    
    
                          )}
                        </>
    
    
    
    
                      ))}
    
    
    
    
    
                 
                 
    
    
                    </div>
                </Modal>
    
    
    
            </div>
    
               )}

</>
       






    )
}

export default AdminMenuEdit