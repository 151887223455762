import React from 'react'
import styles from "./menuVideo.module.css"
import BackgroundVideo from '../backgroundVideo/BackgroundVideo'
import { useSelector } from 'react-redux'

const PF = process.env.REACT_APP_PUBLIC_FOLDER;

const MenuVideo = () => {



  const reduxData = useSelector((state) => state.website);

  return (
    <div   className={styles.carouselCont}>
  
   <img alt="The delicious food to be had @ Connolly's!" src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.menuTopImg}` }/>

   <div className={styles.homeAbso}>
    <p className={styles.homeSpecialP}>{reduxData.data.menuTitle}</p>
  </div>  
  </div>
  )
}

export default MenuVideo