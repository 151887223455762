import React, { useState, useRef, useEffect } from 'react';
import classes from "./StPatsMain.module.css";
import emailjs from '@emailjs/browser'
import axios from 'axios';


const StPatsMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);
  const imgRef = useRef([]);
  const boutonRef = useRef([]);



  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    imgRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    boutonRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);










  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://connollysapi-n476.onrender.com/email/send', {
        recipient,
        subject,
        text,
        to,
      });

      // Clear the form fields after successful submission
      setRecipient('');
      setSubject('');
      setText('');


      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again later.');

    }
  };



  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}> <span>ST PATRICK'S DAY @ CONNOLLY'S</span></div>
        <img src={PF + "guiness.jpg"}  />

      </div>
      <div className={classes.middleDiv}>
        <p className={classes.specialP}>St Patrick's Day  (Sunday, 17th of March 2024)</p>
        <div className={classes.hr}>
          <hr/>
          <hr/>
        </div>
        <p>
          Join us @ Connolly's, on the picturesque harbour of Kinvara Town, for a full day of food, music and festivities!
    
        </p>
      </div>

      <div className={classes.wrapper}>
      <div className={classes.row} ref={(el) => (descRef.current[0] = el)}>
          <div className={classes.imgCont}>
            <img src={PF + "shoot (26).jpg"} alt="Conor Connolly, resident musician of Connolly's" />
          </div>
          <div className={classes.txtBox}>
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>MENUS</p>
            <p className={classes.desc}>
              Start your day with Irish Breakfast classics & delicious Speciality coffee, followed up by gastro classics throughout the day...
        
            </p>
            <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>MENUS</button></a>
            <div className={classes.hr}>
              <hr />
            </div>
          </div>
        </div>
        <div className={classes.row} >
          <div className={classes.imgCont} ref={(el) => (imgRef.current[0] = el)}>
            <img src={PF + "conorEpic.jpg"} alt="Conor Connolly, resident musician of Connolly's" />
          </div>
          <div className={classes.txtBox} ref={(el) => (boutonRef.current[0] = el)}>
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>MUSIC @ CONNOLLY'S</p>
            <p className={classes.desc}>
             TRAD session with Conor Connolly & Special Guests - 7pm to 10pm. Join us in supporting fantastic local talent this St. Patrick's Day.
        
            </p>
            <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>MUSIC</button></a>
            <div className={classes.hr}>
              <hr />
            </div>
          </div>
        </div>
        <div className={classes.row}ref={(el) => (descRef.current[1] = el)} >
          <div className={classes.imgCont} id={classes.idOne}  >
            <img src={PF + "music1.jpg"} alt="Busy in the downstairs bar of Connolly's"/>
          </div>
          <div className={classes.txtBox} id={classes.idTwo} >
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>PERFECT CELEBRATION VENUE</p>
            <p className={classes.desc}>
              With its beautiful location and cosy, fire-warmed, interior, Connolly's is the perfect place to celebrate this St. Patrick's.
             
            </p>
            <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>FIND US</button></a>
            <div className={classes.hr}>
              <hr />
            </div>

          </div>
        </div>
      </div>
    </div>

  )
}

export default StPatsMain;