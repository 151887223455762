import React from 'react'

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'

import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'

import TopComponent from '../../components/topComponent/TopComponent'
import FestivalMain from '../../components/festivalMain/FestivalMain'
import MothersMain from '../../components/mothersMain/mothersMain'


const Mothers = () => {
  const  navStyles={ backgroundColor: "#fff", position: "relative"}

  return (
    <div className="homeContainer">
   
    <TopComponent/>
    <Navbar2 styles2={navStyles}/>


<MothersMain/>
<ColumnHero/>

<DisclaimerRow/>

</div>
  )
}

export default Mothers