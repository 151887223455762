import React from 'react'

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'
import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import WineMain from '../../components/WineExperienceMain/WineExperienceMain'
import TopComponent from '../../components/topComponent/TopComponent'

const WineExperience = () => {
  const  navStyles={ backgroundColor: "#222", position: "relative"}

  return (
    <div className="homeContainer">
  
<TopComponent/>
    <Navbar2 styles2={navStyles}/>

<WineMain/>
<ColumnHero/>

<DisclaimerRow/>

</div>
  )
}

export default WineExperience