import React, { useState, useEffect } from 'react';
import styles from "./sting.module.css";
import { useSelector } from 'react-redux'

const Sting = () => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const [logoVisible, setLogoVisible] = useState(false);
  const [stingVisible, setStingVisible] = useState(true)
  const [stingGone, setStingGone] = useState(false)

  const reduxData = useSelector((state) => state.website);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLogoVisible(true);
    },  1000); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (reduxData.data.landingTitle1 !== "") {
      const timer = setTimeout(() => {
        setStingVisible(false);
      }, 3200); // Adjust the delay as needed

      return () => clearTimeout(timer);
    }
  }, [reduxData]);

  useEffect(() => {


    if (reduxData.data.landingTitle1 !== "") {
      const timer = setTimeout(() => {
        setStingGone(true);
      }, 4200); // Adjust the delay as needed

      return () => clearTimeout(timer);

    }


  }, [reduxData]);







  return (
    <div className={styles.container} style={{
      opacity: stingVisible ? 1 : 0,
      transition: 'all 1.2s ease-in-out',
      visibility: stingVisible ? 'visible' : 'hidden',
      zIndex: stingGone ? "-99" : "99999"


    }}>
      <div className={styles.imgCont}>



        <img
          src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.extraLogo2}`}
          style={{
            opacity: logoVisible ? 1 : 0,
            transition: 'opacity 1s ease-in-out',
            visibility: logoVisible ? 'visible' : 'hidden',
          }}
          alt="Logo"
        />
        <div className={styles.imgAbso}>

          <img
            src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.extraLogo1}`}
            style={{
              opacity: logoVisible ? 0.7 : 0,
              transition: 'opacity 1s ease-in-out',
              visibility: logoVisible ? 'visible' : 'hidden',
            }}
            alt="Logo2"
            />
        </div>
      </div>
    </div>
  );
}

export default Sting;