import React from 'react'
import styles from "./menus.module.css"

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'

import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import MenuTabs from '../../components/menuTabs/MenuTabs'

import TopComponent from '../../components/topComponent/TopComponent'
import MenuVideo from '../../components/menuVideo/MenuVideo'
import { useSelector} from 'react-redux'

const Menus = () => {
  const  navStyles={ backgroundColor: "#fff", position: "relative"}

  
  const reduxData = useSelector((state) => state.website);

  return (
    <div className="homeContainer">
  
    <TopComponent/>
    <Navbar2 styles2={navStyles}/>
    
    <MenuVideo/> 

    <div className={styles.container}>

    <div className={styles.middleDiv}>
  <p className={styles.specialP}> {reduxData.data.menuDescTitle}</p>
  <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
  <p> {reduxData.data.menuDesc}</p>
  <p className={styles.italics}> {reduxData.data.menuMessage}</p>

  
</div>


    <MenuTabs/>

     </div>
    


<ColumnHero/>

<DisclaimerRow/>

</div>
  )
}

export default Menus