import React, { useState, useRef, useEffect } from 'react';
import classes from "./mothersMain.module.css";
import emailjs from '@emailjs/browser'
import axios from 'axios';


const MothersMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);
  const imgRef = useRef([]);
  const boutonRef = useRef([]);



  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    imgRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    boutonRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);










  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://connollysapi-n476.onrender.com/email/send', {
        recipient,
        subject,
        text,
        to,
      });

      // Clear the form fields after successful submission
      setRecipient('');
      setSubject('');
      setText('');


      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again later.');

    }
  };



  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}> <span>MOTHER'S DAY @ CONNOLLY'S</span></div>
        <img src={PF + "shoot (11).jpg"} />
      </div>
      <div className={classes.middleDiv}>
        <p className={classes.specialP}>Treat Mum to a grand affair!</p>
        <div className={classes.hr}>
          <hr />
          <hr />
        </div>
        <p>
          Delicious food in the cosy & inviting atmosphere of Connolly's.
          The perfect place to take Mum, Grandma/Nan, an Aunt, or any wonderful person that means the world to you on this special day!</p>
      </div>

      <div className={classes.wrapper}>
        <div className={classes.row} ref={(el) => (descRef.current[0] = el)}>
          <div className={classes.imgCont}>
            <img src={PF + "wed16.jpeg"} />
          </div>
          <div className={classes.txtBox}>
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>COSY & INVITING VENUE</p>
            <p className={classes.desc}>
             With its view of the harbour and cosy, fire-warmed, interior, Connolly's is a wonderful place to take your special someone in order to relax and enjoy some well-earned dinner & drinks. 
            </p>
            <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK NOW</button></a>
            <div className={classes.hr}>
              <hr />
            </div>
          </div>
        </div>
        <div className={classes.row} >
          <div className={classes.imgCont} id={classes.idOne} ref={(el) => (imgRef.current[0] = el)} >
            <img  src={PF + "shoot (45).jpg"} />
          </div>
          <div className={classes.txtBox} id={classes.idTwo} ref={(el) => (boutonRef.current[0] = el)}>
            <div className={classes.hr}>
              <hr />
            </div>
            <p className={classes.specialP}>MOTHER'S DAY MENU</p>
            <p className={classes.desc}>

              Mother's Day is set to be a culinary delight, with succulent roasts during the day (12pm - 3pm) and gourmet modern Irish fine-dining in the evening (6pm - 8.30pm)...
            
            </p>
            <a className={classes.a} href="/menus?mothersday"> <button className={classes.giftBtn}>MENU</button></a>
            <div className={classes.hr}>
              <hr />
            </div>
          </div>
        </div>
      </div>



      {/* <div style={{backgroundImage: `url('${ PF +  "beauty.webp"}')`}} className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (descRef.current[1] = el)} onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>

      <p className={classes.mess}>For all career inquiries, contact Jason O'Neill for more details. </p>
      
      <form onSubmit={handleSubmit}>
      
      <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />

<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
       
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />

<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />

      <button className={classes.getBtn}>SEND</button>
    </form>
      
  </div> 


  </div>*/}
    </div>

  )
}

export default MothersMain;