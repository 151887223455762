import React from 'react'
import styles from "./disclaimerMain.module.css"
import { useSelector } from 'react-redux'


const DisclaimerMain = () => {

  const reduxData = useSelector((state) => state.website);

    
  return (
    <div className={styles.container}>
        <div className={styles.wrapper}>
            <p className={styles.specialP}>DISCLAIMER</p>
        <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
            
            <p>{reduxData.data.disclaimer}</p>
                
  

    
         
          
       
       
        </div>
    </div>
  )
}

export default DisclaimerMain