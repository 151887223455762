import React, { useState, useRef, useEffect } from 'react';
import classes from "./careersMain.module.css";
import emailjs from '@emailjs/browser'
import axios from 'axios';
import { useSelector } from 'react-redux'



const CareersMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);


  const reduxData = useSelector((state) => state.website);




  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);










  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://connollysapi-n476.onrender.com/email/send', {
        recipient,
        subject,
        text,
        to,
      });

      // Clear the form fields after successful submission
      setRecipient('');
      setSubject('');
      setText('');


      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again later.');

    }
  };



  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}> <span>{reduxData.data.careerTitle}</span></div>
        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.careerTopImg}`} alt="Chef Jason O'Neill masterfully using the Mibrasa grill" />

      </div>
      <div className={classes.middleDiv}>
        <p className={classes.specialP}>{reduxData.data.careerDescTitle}</p>
        <div className={classes.hr}>
          <hr />
          <hr />

        </div>
        <p>
          {reduxData.data.careerDesc}
        </p>


      </div>

      <div className={classes.photosDiv} ref={(el) => (descRef.current[0] = el)}>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.careerImg1}`} alt="Wide of a beautiful kitchen" />
        </div>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.careerImg2}`} alt="Careers  @ Connolly's" />
        </div>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.careerImg3}`} alt="Careers  @ Connolly's" />
        </div>
      </div>




      {/* <div style={{backgroundImage: `url('${ PF +  "beauty.webp"}')`}} className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (descRef.current[1] = el)} onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>

      <p className={classes.mess}>For all career inquiries, contact Jason O'Neill for more details. </p>
      
      <form onSubmit={handleSubmit}>
      
      <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />

<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
       
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />

<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />

      <button className={classes.getBtn}>SEND</button>
    </form>
      
  </div> 


  </div>*/}
    </div>

  )
}

export default CareersMain;