import React, {useEffect, useRef} from 'react'
import styles from "./giftsMain.module.css"

const GiftsMain = () => {



    const descRef = useRef([]);
    
   


    useEffect(() => {
        const appearOptions = {
          threshold: 0.07,
        };
    
        const appearOnScroll = new IntersectionObserver((entries, observer) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(styles.appear);
              observer.unobserve(entry.target);
            }
          });
        }, appearOptions);
    
        descRef.current.forEach((desc) => {
          appearOnScroll.observe(desc);
        });

      
    
      
        return () => {
          appearOnScroll.disconnect();
        };
      }, []);

    const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  return (
    
    <div className={styles.container}>
        <div className={styles.imgWrapper}>
        <div className={styles.abso}><p>GIFTS & EXPERIENCES</p></div>
            <img src={PF + "U9.png"} alt=""/>
            
        </div>
        <div className={styles.middleDiv}>
  <p className={styles.specialP}>PREMIUM GIFTS @ CONNOLLY'S</p>
  <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
  <p>We have a wide selection of Gifts & Experiences available for all occasions. Be it Valentines, Christmas, or celebration for its own sake, Upstairs @ Connolly's can cater to your needs. </p>

  
</div>
        <div className={styles.giftWrapper} >
            <div className={styles.gift} ref={(el) => (descRef.current[0] = el)}>
                <div className={styles.imgDiv}>
                    <img src={PF + "U7.png"}/>
                </div>
                <div className={styles.textWrap}>
                    <p className={styles.specialP}>Gift or experience</p>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                        Saepe amet voluptates accusamus iure, sequi dolorem earum, omnis obcaecati perspiciatis blanditiis laborum similique perferendis, dignissimos adipisci. Praesentium obcaecati modi sequi eaque.
                    </p>
                  
                   
                </div>
            </div>
            <div className={styles.gift} ref={(el) => (descRef.current[1] = el)}>
                <div className={styles.imgDiv}>
                    <img src={PF + "U7.png"}/>
                </div>
                <div className={styles.textWrap}>
                <p className={styles.specialP}>Gift or experience</p>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                        Saepe amet voluptates accusamus iure, sequi dolorem earum, omnis obcaecati perspiciatis blanditiis laborum similique perferendis, dignissimos adipisci. Praesentium obcaecati modi sequi eaque.
                    </p>
                    
                   
                </div>
            </div>
            <div className={styles.gift} ref={(el) => (descRef.current[2] = el)}>
                <div className={styles.imgDiv}>
                    <img src={PF + "U7.png"}/>
                </div>
                <div className={styles.textWrap}>
                <p className={styles.specialP}>Gift or experience</p>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                        Saepe amet voluptates accusamus iure, sequi dolorem earum, omnis obcaecati perspiciatis blanditiis laborum similique perferendis, dignissimos adipisci. Praesentium obcaecati modi sequi eaque.
                    </p>
               
                   
                </div>
            </div>
            <div className={styles.gift} ref={(el) => (descRef.current[3] = el)}>
                <div className={styles.imgDiv}>
                    <img src={PF + "U7.png"}/>
                    
                </div>
                <div className={styles.textWrap}>
                <p className={styles.specialP}>Gift or experience</p>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                        Saepe amet voluptates accusamus iure, sequi dolorem earum, omnis obcaecati perspiciatis blanditiis laborum similique perferendis, dignissimos adipisci. Praesentium obcaecati modi sequi eaque.
                    </p>
                
                   
                </div>
            </div>
            <div className={styles.gift} ref={(el) => (descRef.current[4] = el)}>
                <div className={styles.imgDiv}>
                    <img src={PF + "U7.png"}/>
                </div>
                <div className={styles.textWrap}>
                <p className={styles.specialP}>Gift or experience</p>
                    <div className={styles.hr}>
            <hr/>
            <hr/>

            </div>
                    <p>
                        Lorem ipsum, dolor sit amet consectetur adipisicing elit. 
                        Saepe amet voluptates accusamus iure, sequi dolorem earum, omnis obcaecati perspiciatis blanditiis laborum similique perferendis, dignissimos adipisci. Praesentium obcaecati modi sequi eaque.
                    </p>
                  
                </div>
            </div>
            
        </div>

       
        <div className={styles.extraDiv}>
            <p className={styles.specialP}> Can't find what you are looking for? </p>
           <a href="/contact"> <button>CONTACT US</button></a>
        </div>
    </div>
  )
}

export default GiftsMain