import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: []
 
 }



 export const musicSlice = createSlice({
    name: 'music',
    initialState,
    reducers: {
        getMusicData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getMusicData} = musicSlice.actions;

export default musicSlice.reducer;



