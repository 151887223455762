import React, { useState, useEffect } from 'react'
import Navbar2 from '../../components/navbar2/navbar2';
import "./home.css";
import ColumnHero from '../../components/columnHero/ColumnHero';
import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow';
import Sting from '../../components/sting/Sting';
import HomeMain from '../../components/homeMain/HomeMain';
import TopComponent from '../../components/topComponent/TopComponent';
import HomeVideo from '../../components/HomeVideo/HomeVideo';
import MediaFooter from '../../components/mediaFooter/MediaFooter';


const Home = () => {

  
  const [stingGo, setStingGo] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setStingGo(true)

    }, 2300); // Adjust the delay as needed

    return () => clearTimeout(timer);
  }, []);










  const styles = {
    width: "100%",
    visibility: stingGo ? "visible" : "hidden",

    opacity: stingGo ? "1" : "0",
    zIndex: stingGo ? "999" : "-1",

  }









  return (





    <div className="homeContainer">
      <Sting />
      <div style={styles}>
        <MediaFooter />
        <TopComponent />
        <Navbar2 />
        <HomeVideo />
        <HomeMain />
        <ColumnHero />
        <DisclaimerRow />
      </div>
    </div>




  )
}

export default Home;