import React from 'react'

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'

import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import OurTeamMain from '../../components/ourTeamMain/OurTeamMain'

import TopComponent from '../../components/topComponent/TopComponent'


const OurTeam = () => {
  const  navStyles={ backgroundColor: "#fff", position: "relative"}

  return (
<div className="homeContainer">
  
    <TopComponent/>
    <Navbar2 styles2={navStyles}/>
    
   

    <OurTeamMain/>

<ColumnHero/>

<DisclaimerRow/>

</div>
  )
}

export default OurTeam