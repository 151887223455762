import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import classes from "./admin.module.css"
import { request } from '../../util/fetchAPI';
import { register, logout } from '../../redux/authSlice'
import { AiOutlineClose } from 'react-icons/ai'
import Modal from 'react-modal';
import AdminWebsite from '../../components/EditComponents/AdminWebsite/AdminWebsite'
import AdminMenus from '../../components/EditComponents/AdminMenus/AdminMenus';
import AdminOpeningHours from '../../components/EditComponents/AdminOpeningHours/AdminOpeningHours';
import AdminTerms from '../../components/EditComponents/AdminTerms/AdminTerms';
import AdminPrivacy from '../../components/EditComponents/AdminPrivacy/AdminPrivacy';
import AdminRoutes from '../../components/EditComponents/AdminRoutes/AdminRoutes';
import AdminMusic from '../../components/EditComponents/AdminMusic/AdminMusic';
import AdminControl from '../../components/EditComponents/AdminControl/AdminControl';






const PF = process.env.REACT_APP_PUBLIC_FOLDER;


const preloadImage = (url) => {
    const img = new Image();
    img.src = url;

    return img;


};


const background = preloadImage(PF + "oldbatch5.jpg");


const Admin = () => {



    //getAll states

    const [allProperties, setAllProperties] = useState([]);
    const [allBlogs, setAllBlogs] = useState([]);
    const [allPress, setAllPress] = useState([]);



    //TAB STATES
    const [activeTab, setActiveTab] = useState('tab1');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };






    const { user, token } = useSelector((state) => state.auth)

    const reduxData = useSelector((state) => state.website)


    //const userImage = preloadImage(`https://katteochapi.onrender.com/images/${user?.profileImg}`);


    //ALL SELF-TITLED STATES

    const [state, setState] = useState({});
    const [state2, setState2] = useState({});
    const [state3, setState3] = useState(user);
    const [state4, setState4] = useState({
        title: "",
        author: "",
        strap: "",
        article: "",
        img: ""
    });


    const [showForm, setShowForm] = useState(false);
    const [showForm2, setShowForm2] = useState(false);
    const [showForm3, setShowForm3] = useState(false);
    const [showForm4, setShowForm4] = useState(false);
    const [showForm5, setShowForm5] = useState(false);
    const [showForm6, setShowForm6] = useState(false);
    const [showForm7, setShowForm7] = useState(false);




    const [selectedEvent, setSelectedEvent] = useState({

        createdAt
            :
            "2023-06-02T11:18:36.142Z",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",
        },
        desc1
            :
            "",

        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",
    });

    const [selectedPress, setSelectedPress] = useState({
        pressTitle: "",
        date: "",
        desc: "",
        pressImg: "",
        externalLink: "",
        strap: "",
        article: "",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",

        },
        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",
    });
    const [selectedBlog, setSelectedBlog] = useState({
        title: "",
        author: "",

        img: "",
        strap: "",
        article: "",
        currentOwner
            :
        {
            createdAt
                :
                "2023-06-18T10:52:59.113Z",
            desc
                :
                "With over 12 years experience in property finance and marketing, Finn is a key player in our team. Specialising in property scouting, client outreach and valuations",
            email
                :
                "finn.callaghan@gmail.com",
            profileImg
                :
                "2796bc3f-cf24-4311-96f1-9d1035fe885dIMG-20230613-WA0001.jpg",
            updatedAt
                :
                "2023-06-18T10:59:48.199Z",
            username
                :
                "Finn Callaghan",
            __v
                :
                0,
            _id
                :
                "648ee20b6d9d1deb625c3053",

        },
        updatedAt
            :
            "2023-08-23T18:12:05.019Z",
        __v
            :
            0,
        _id
            :
            "6479d00c23fc692ef21b1872",
    });


    const [newPassword, setNewPassword] = useState("")

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const [isActive, setIsActive] = useState(true);





    const [currentPassword, setCurrentPassword] = useState("")


    //WITH PROFILE UPDATE
    const [photo4, setPhoto4] = useState("")

    //WITH LIST PROPERTY, MAIN IMAGE AND SUB IMAGES
    const [photo, setPhoto] = useState("") // Main
    const [photo2, setPhoto2] = useState("")
    const [photo3, setPhoto3] = useState("")
    const [photo5, setPhoto5] = useState("")
    const [photo6, setPhoto6] = useState("")
    const [photo7, setPhoto7] = useState("")
    const [photo8, setPhoto8] = useState("")
    const [photo9, setPhoto9] = useState("")
    const [photo10, setPhoto10] = useState("")
    const [photo11, setPhoto11] = useState("")
    const [photo12, setPhoto12] = useState("")
    const [photo13, setPhoto13] = useState("")

    //GALLERY IMAGES


    const [photos, setPhotos] = useState([]);
    const [photos2, setPhotos2] = useState([]);



    const [isLoading, setIsLoading] = useState(false);






    const handleLoad = () => {

        console.log("it's being handled")
        setTimeout(() => {

            setIsLoading(true)

        }, [2000]);
    }











    useEffect(() => {
        let inactivityTimeout;

        const handleInactivity = () => {
            setIsActive(false);

        };

        const resetInactivityTimeout = () => {
            if (inactivityTimeout) {
                clearTimeout(inactivityTimeout);
            }

            inactivityTimeout = setTimeout(handleInactivity, 4800000);
        };

        const handleUserActivity = () => {
            resetInactivityTimeout();
            setIsActive(true);
        };


        resetInactivityTimeout();


        window.addEventListener('mousemove', handleUserActivity);
        window.addEventListener('keydown', handleUserActivity);


        return () => {
            clearTimeout(inactivityTimeout);
            window.removeEventListener('mousemove', handleUserActivity);
            window.removeEventListener('keydown', handleUserActivity);
        };
    }, [allProperties]);

    useEffect(() => {

        if (isActive === false) {
            dispatch(logout());
        }

    }, [isActive])


    useEffect(() => {
        Modal.setAppElement('#root');

    }, []);






    const handleCloseForm = () => {
        setShowForm(false)
        setShowForm2(false)
        setShowForm6(false)
        setShowForm7(false)


        setPhoto(null)
        setState({})
        setState2({})
        setState4({})
    }


    const handleLogout = () => {
        dispatch(logout());
        navigate("/admin-login");
    }

    const deletePhoto = (photo) => {


        setPhotos((prevPhotos) => prevPhotos.filter((p) => p !== photo));
    };




    const handleNewPassword = (e) => {
        setNewPassword(e.target.value)
    }


    //ALL STATE HANDLERS



    useEffect(() => {

        console.log("STate 4:" + Object.keys(state4))

    }, [state4])



    const handleState = (e) => {
        const { name, value } = e.target;
        const newValue = value;
        setState({ ...state, [name]: newValue })
    }

    const handleState2 = (e) => {
        const { name, value } = e.target;
        const newValue = value;
        setState2({ ...state2, [name]: newValue })
    }

    const handleState4 = (e) => {
        const { name, value } = e.target;
        const newValue = value;
        setState4({ ...state4, [name]: newValue })
    }


    const handleState3 = (e) => {
        setState3(prev => {
            return { ...prev, [e.target.name]: e.target.value }
        })
    }


    const handleCurrentPassword = (e) => {


        setCurrentPassword(e.target.value)

    }


    useEffect(() => {


        if (allProperties.length > 0) {
            handleLoad();
            console.log("All Properties:" + allProperties[0].artist)
        }



    }, [allProperties])



















    /*DELETE FUNCTIONS


    const deleteProperty = async () => {
        try {
            await request(`/portfolio/${selectedEvent._id}`, 'DELETE', { Authorization: `Bearer ${token}` });


            fetchAllProperties();
            alert("Your property has been successfully deleted!")

        } catch (error) {

            console.log(error);

            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };


    const deleteBlog = async () => {
        try {
            await request(`/blog/${selectedBlog._id}`, 'DELETE', { Authorization: `Bearer ${token}` });



            fetchAllBlogs();

            alert("Your blog has been successfully deleted!")

        } catch (error) {

            alert(error);
        }
    };


    const deletePress = async () => {
        try {
            await request(`/press/${selectedPress._id}`, 'DELETE', { Authorization: `Bearer ${token}` });



            fetchAllPress();

            alert("Your press item has been successfully deleted!")

        } catch (error) {

            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };





    const confirmDelete = () => {

        deleteProperty();


        setShowForm(false);


    };



    const confirmDelete2 = () => {

        deletePress();


        setShowForm4(false);


    };


    const confirmDelete3 = () => {

        deleteBlog();


        setShowForm5(false);


    }; */



    // FORM OPENERS

    const handleOpenForm3 = () => {
        setShowForm3(true);


    }


    //CREATE PORTFOLIO, PRESS AND BLOG ITEMS


    const handleListProperty = async (e) => {
        e.preventDefault();

        let dateStampedPhotos = [];

        let filename1 = "";
        let filename2 = "";
        let filename3 = "";

        let filename5 = "";
        let filename6 = "";
        let filename7 = "";
        let filename8 = "";
        let filename9 = "";
        let filename10 = "";
        let filename20 = "";


        if (photos.length > 0) {
            const uploadPromises = photos.map(async (photo, index) => {
                if (photo) {
                    const formData = new FormData();
                    const filename = crypto.randomUUID() + photo.name;

                    dateStampedPhotos.push(filename);
                    formData.append("filename", filename);
                    formData.append("image", photo);

                    await request("/upload/image", "POST", {}, formData, true);
                }
            });

            try {
                await Promise.all(uploadPromises);
            } catch (error) {
                console.error(error);
            }
        }



        if (photo) {
            const formData = new FormData();

            filename1 = crypto.randomUUID() + photo.name;
            formData.append("filename", filename1);
            formData.append("image", photo);

            await request('/upload/image', "POST", {}, formData, true);
        }


        if (photo2) {
            const formData2 = new FormData();

            filename2 = crypto.randomUUID() + photo2.name;
            formData2.append("filename", filename2); // Modified field name
            formData2.append("image", photo2); // Modified field name

            await request('/upload/image', "POST", {}, formData2, true);
        }







        if (photo3) {
            const formData3 = new FormData();

            filename3 = crypto.randomUUID() + photo3.name;
            formData3.append("filename", filename3); // Modified field name
            formData3.append("image", photo3); // Modified field name

            await request('/upload/image', "POST", {}, formData3, true);
        }

        if (photo5) {
            const formData5 = new FormData();

            filename5 = crypto.randomUUID() + photo5.name;
            formData5.append("filename", filename5); // Modified field name
            formData5.append("image", photo5); // Modified field name

            await request('/upload/image', "POST", {}, formData5, true);
        }

        if (photo6) {
            const formData6 = new FormData();

            filename6 = crypto.randomUUID() + photo6.name;
            formData6.append("filename", filename6); // Modified field name
            formData6.append("image", photo6); // Modified field name

            await request('/upload/image', "POST", {}, formData6, true);
        }

        if (photo7) {
            const formData7 = new FormData();

            filename7 = crypto.randomUUID() + photo7.name;
            formData7.append("filename", filename7); // Modified field name
            formData7.append("image", photo7); // Modified field name

            await request('/upload/image', "POST", {}, formData7, true);
        }
        if (photo8) {
            const formData8 = new FormData();

            filename8 = crypto.randomUUID() + photo8.name;
            formData8.append("filename", filename8); // Modified field name
            formData8.append("image", photo8); // Modified field name

            await request('/upload/image', "POST", {}, formData8, true);
        }
        if (photo9) {
            const formData9 = new FormData();

            filename9 = crypto.randomUUID() + photo9.name;
            formData9.append("filename", filename9); // Modified field name
            formData9.append("image", photo9); // Modified field name

            await request('/upload/image', "POST", {}, formData9, true);
        }
        if (photo10) {
            const formData10 = new FormData();

            filename10 = crypto.randomUUID() + photo10.name;
            formData10.append("filename", filename10); // Modified field name
            formData10.append("image", photo10); // Modified field name

            await request('/upload/image', "POST", {}, formData10, true);
        }


        if (photo13) {
            const formData13 = new FormData();

            filename20 = crypto.randomUUID() + photo13.name;
            formData13.append("filename", filename20); // Modified field name
            formData13.append("image", photo13); // Modified field name

            await request('/upload/image', "POST", {}, formData13, true);
        }



        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            };

            const requestData = {
                ...state,
                galleryImages: dateStampedPhotos,
                type: selectedType,
                mainImage: filename1,
                subImage1: filename2,
                subImage2: filename3,
                subImage3: filename5,
                subImage4: filename6,
                subImage5: filename7,
                subImage6: filename8,
                subImage7: filename9,
                subImage8: filename10,
                thumbImage: filename20

            };

            await request('/portfolio', 'POST', options, requestData);


            setPhotos([]);

            setPhoto("");
            setPhoto2("");
            setPhoto3("");
            setPhoto5("");
            setPhoto6("");
            setPhoto7("");
            setPhoto8("");
            setPhoto9("");
            setPhoto10("");

            setState({
                ...state,
                descTitle1: "",
                descTitle2: "",
                desc1: "",
                desc2: "",
                name: ""

            })





            alert("Your property was sucessfully created!");
        } catch (error) {
            console.error(error);
            alert("Your authentication token is expired. Please login again via the sign in page.");
        }
    };






    //SELECTOR FUNCTIONS



    useEffect(() => {

        console.log("This is the selected event:" + selectedEvent)

    }, [selectedEvent])


    const handleSelectedEvent = async (id) => {
        const data = await request(`/portfolio/find/${id}`, "GET");
        setSelectedEvent(data);


    }


    const handleSelectedPress = async (id) => {
        const data = await request(`/press/find/${id}`, "GET");
        setSelectedPress(data);


    }


    const handleSelectedBlog = async (id) => {
        const data = await request(`/blog/find/${id}`, "GET");
        setSelectedBlog(data);


    }


    //UPLOAD PHOTO FUNCTIONS


    const handleUploadPhoto = (e) => {
        const files = Array.from(e.target.files);

        setPhotos([...photos, ...files]);
    };


    //PROPERTY TYPE FOR PORTFOLIO



    const [selectedType, setSelectedType] = useState("")


    const handleTypeChange = (event) => {
        const { value } = event.target;
        setSelectedType(value);

    };
















    const handleSubmit = async (e) => {

        e.preventDefault()




        try {

            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }


            let filename = null
            if (photo4 !== undefined) {
                const formData = new FormData()
                filename = crypto.randomUUID() + photo4.name
                formData.append("filename", filename)
                formData.append('image', photo4)

                await request('/upload/image', "POST", {}, formData, true)



                const data = await request(`/superUser/user/${user._id}`, "PUT", options, { ...state3, profileImg: filename, password: newPassword, currentPassword: currentPassword })
                dispatch(register(data))
                alert("Your profile has been successfully updated")
                setShowForm3(false)
                setNewPassword("")
                setCurrentPassword("")
                setPhoto4(undefined)




            } else {


                const data = await request(`/superUser/user/${user._id}`, "PUT", options, { ...state3, password: newPassword, currentPassword: currentPassword })
                dispatch(register(data))
                alert("Your profile has been successfully updated")
                setShowForm3(false)
                setNewPassword("")
                setCurrentPassword("")
                setPhoto4(undefined)



            }









        } catch (error) {
            console.error(error)
            alert("Please enter your current password before making changes to your profile. Or signin again to refresh your authentication token");
        }


    }











    return (
        <div className={classes.container} >
          


            
            {user ? (

                <div className={classes.wrapper}>





                    <>

                        <div className={classes.topBar}>
                            <div className={classes.section}>
                                <img alt="logo" src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.logoImg}`} />


                            </div>
                            <div className={classes.section2}>
                                <p className={classes.logout} onClick={handleLogout}>Log Out</p>
                                <p className={classes.logout} onClick={handleOpenForm3}>
                                    {user.username}
                                </p>
                            </div>






                        </div>



                        <AdminWebsite />




                        <AdminMenus currentUser={"super"} />



                        <div className={classes.editorContainer}>
                            <AdminOpeningHours currentUser={"super"} />
                            <AdminTerms />
                            <AdminPrivacy />
                        </div>

                        <AdminRoutes/>
                        <AdminMusic />

                        <AdminControl/>


































                        <h5 className={classes.claimer}>Employee Dashboard and associated website are property of Connolly's Pub 2024 </h5>
                    </>

                    {showForm3 && (


                        <Modal
                            isOpen={showForm3}
                            onRequestClose={() => setShowForm3(false)}
                            contentLabel="Edit User Modal"
                            className="modal"
                            style={{
                                overlay: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    zIndex: "999999999999999999999999999999999999",
                                },
                                content: {
                                    width: 'auto',
                                    height: 'auto',
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    margin: "0",
                                    padding: "0",
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white',
                                    backgroundColor: ' #f3c7c7',
                                    border: "0",
                                    borderRadius: "20px",
                                    boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                    overflow: "auto",
                                    WebkitOverflowScrolling: "touch", // For iOS Safari
                                    scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                    scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization

                                },
                            }}
                        >



                            <AiOutlineClose className={classes.removeIcon} onClick={() => { setShowForm3(false) }} />


                            <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                                <h2>Edit User</h2>
                                <form onSubmit={handleSubmit}>

                                    <div className={classes.inputs}>
                                        <label>
                                            Username:
                                            <input type="text" name="username" value={state3.username} placeholder='Username' onChange={handleState3} required />
                                        </label>
                                        <label>
                                            Email:
                                            <input type="email" name="email" value={state3.email} placeholder='Email' onChange={handleState3} required />
                                        </label>
                                        <label>
                                            NEW PASSWORD:
                                            <input type="password" className={classes.newPass} placeholder='newPassword' onChange={handleNewPassword} />
                                        </label>





                                    </div>




                                    <h3 className={classes.scripTitle}>Authentication</h3>
                                    <p className={classes.passMessage}>Please enter your <span className={classes.greenText}>CURRENT PASSWORD</span> to make any changes to your profile, including creating a new password.</p>

                                    <div className={classes.inputs}>
                                        <label>
                                            CURRENT PASSWORD:
                                            <input className={classes.currentPass} type="password" placeholder='currentPassword' onChange={handleCurrentPassword} />

                                        </label>

                                    </div>


                                    <button className={classes.formButton2} type="submit">UPDATE</button>

                                </form>

                            </div>

                        </Modal>)
                    }




                    {/* EDIT DATABASE MODALS*/}







                    <Modal
                        isOpen={showForm2}
                        onRequestClose={() => setShowForm2(false)}
                        contentLabel="Delete User Modal"
                        className="modal"

                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999",
                            },
                            content: {
                                width: '55%',
                                height: "80vh",
                                position: 'absolute',
                                margin: '0',
                                padding: '0',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >

                        <AiOutlineClose onClick={() => {
                            setShowForm2(false);
                        }} className={classes.removeIcon} />




                    </Modal>


                    <Modal
                        isOpen={showForm6}
                        onRequestClose={() => setShowForm6(false)}
                        contentLabel="Delete User Modal"
                        className="modal"

                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999",
                            },
                            content: {
                                width: '55%',
                                height: "80vh",
                                position: 'absolute',
                                margin: '0',
                                padding: '0',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >

                        <AiOutlineClose onClick={() => {
                            setShowForm6(false);
                        }} className={classes.removeIcon} />




                    </Modal>

                    <Modal
                        isOpen={showForm7}
                        onRequestClose={() => setShowForm7(false)}
                        contentLabel="Delete User Modal"
                        className="modal"

                        style={{
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                zIndex: "999",
                            },
                            content: {
                                width: '55%',
                                height: "80vh",
                                position: 'absolute',
                                margin: '0',
                                padding: '0',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                color: 'white',
                                backgroundColor: "#2d2c2c",
                                border: "0",
                                borderRadius: "20px",
                                boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                                overflow: "auto",
                                WebkitOverflowScrolling: "touch", // For iOS Safari
                                scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                                scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                            },
                        }}
                    >

                        <AiOutlineClose onClick={() => {
                            setShowForm7(false);
                        }} className={classes.removeIcon} />




                    </Modal>


                    {/*  DELETE MODALS */}










                </div>) : (<div className={classes.wrapper2}>

                    <div className={classes.topbar}>
                        <div className={classes.section}>
                            <img alt="logo" src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.logoImg}`} />
                            <h1>Upstairs @ Connolly's</h1>

                        </div>

                    </div>

                    <h2 className={classes.claimer}>Please sign in to use the Connolly's Employee Dashboard. Click <a className={classes.here} href="/admin-login">HERE</a></h2>


                </div>


            )}
        </div>
    )
}

export default Admin