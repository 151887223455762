import React from 'react'

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'

import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'

import GiftsMain from '../../components/giftsMain/GiftsMain'
import TopComponent from '../../components/topComponent/TopComponent'

const Gifts = () => {
  const  navStyles={ backgroundColor: "#fff", position: "relative"}
  

  return (
    <div className="homeContainer">
  
<TopComponent/>
    <Navbar2 styles2={navStyles}/>
    <GiftsMain/>


<ColumnHero/>

<DisclaimerRow/>

    </div>
  )
}

export default Gifts