import React, { useEffect, useRef } from 'react'
import styles from "./columnHero.module.css"
import { useSelector } from 'react-redux'



const ColumnHero = () => {

  const descRef = useRef([]);

  const reduxData = useSelector((state) => state.website);
  const menuData = useSelector((state) => state.menus.data);
  const openingHoursData = useSelector((state) => state.openingHours.data);


  useEffect(() => {
    const appearOptions = {
      threshold: 0.001,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);



  return (
    <div className={styles.container}>
      <div className={styles.wrapper} ref={(el) => (descRef.current[0] = el)}>
        <div className={styles.column}>
          <p className={styles.specialP}>CONTACT US</p>
          <div className={styles.hr}>
            <hr />
            <hr />

          </div>
          <div className={styles.pDiv}>{reduxData.data.addressLine2}</div>
          <div className={styles.pDiv}>
            <span className={styles.specialSpan}>{reduxData.data.contactNumber}</span>
            <span>Restaurant reservations</span>
          </div>


          <div className={styles.email}>{reduxData.data.contactEmail}</div>

          <div className={styles.hr}>
            <hr />
            <hr />

          </div>


          <div className={styles.pDiv}><a href="/contact">View map →</a></div>
          <p></p>



        </div>
        <div className={styles.column}>

          <p className={styles.specialP}>OPENING HOURS</p>
          <div className={styles.hr}>
            <hr />
            <hr />

          </div>



          {openingHoursData?.map((object, index) => (

            <>

              {object.elementType === "Area" && (

                <div className={styles.pDiv}>{object.content}</div>



              )

              }
              {object.elementType === "Days" && (

                <div className={styles.pDiv2}>{object.content}</div>



              )

              }

              {object.elementType === "Times" && (

                <div className={styles.pDiv}>{object.content}</div>


              )

              }
            </>



          ))}

         


          <div className={styles.hr}>
            <hr />
            <hr />

          </div>

          <p className={styles.specialP}>RESTAURANT INFORMATION</p>
          <div className={styles.hr}>
            <hr />
            <hr />

          </div>
          <div className={styles.pDiv}>


            {reduxData.data.restaurantInfo?.map((info, index) => (

              <span key={index}>{info}</span>

            ))}


          </div>

        </div>




        <div className={styles.column}>

          <p className={styles.specialP}>MENUS & WINELIST</p>
          <div className={styles.hr}>
            <hr />
            <hr />

          </div>


          <div className={styles.pDiv}><a href="/menus">All Menus</a></div>


          {menuData.map((menu, index) => (

            <div key={index} className={styles.pDiv}><a href={`/menus?${menu.route}`}>{menu.route}</a></div>

          ))}






        </div>
        <div className={styles.column}>

          <p className={styles.specialP}>BOOK A TABLE</p>
          <div className={styles.hr}>
            <hr />
            <hr />

          </div>


          <a href="/book-a-table"><button>BOOK NOW</button></a>

        </div>
      </div>
    </div>
  )
}

export default ColumnHero