import React from 'react'

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'

import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'
import MusicMain from '../../components/musicMain/MusicMain'
import TopComponent from '../../components/topComponent/TopComponent'
import MusicVideo from '../../components/musicVideo/MusicVideo'
import { useEffect } from 'react'

const Music = (newEvents) => {
  const  navStyles={ backgroundColor: "#fff", position: "relative"}


  useEffect(() => {

    console.log(newEvents)

  }, [newEvents])

  return (
    <div className="homeContainer">
  
<TopComponent/>
    <Navbar2 styles2={navStyles}/>
    <MusicVideo/>
    <MusicMain newEvents={newEvents}/>


<ColumnHero/>

<DisclaimerRow/>

</div>
  )
}

export default Music