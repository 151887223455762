import React, { useState, useEffect } from 'react'
import classes from "./adminOpeningHours.module.css"
import { request } from '../../../util/fetchAPI';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useSelector } from 'react-redux'
import { AiOutlineClose } from 'react-icons/ai'
import { IoMdAddCircle } from "react-icons/io";
import Modal from 'react-modal';



const AdminOpeningHours = ({ currentUser }) => {


    const { token, user } = useSelector((state) => state.auth)
    const [openingHours, setOpeningHours] = useState([]);
    const [currentType, setCurrentType] = useState("")
    const [currentWord, setCurrentWord] = useState("")
    const [id, setId] = useState("")


    const handleWord = (e) => {
        setCurrentWord(e.target.value)
    }

    const handleTypeChange = (e) => {
        setCurrentType(e.target.value);

    };



    const fetchOpeningHours = async () => {
        const data = await request(`/openingHours/getAll`, 'GET');
        setOpeningHours(data[0].contents);
        setId(data[0]._id)
        console.log(data[0]);

    };


    useEffect(() => {
        fetchOpeningHours()


    }, [])




    const handleAddItem = () => {


        let newItem = {}

        if (currentType !== "") {




            newItem = {
                elementType: currentType,
                content: currentWord
            }





            setOpeningHours([...openingHours, newItem]);

            setCurrentWord("");

            setCurrentType("");
        } else {
            alert("You must set an element type")
        }


    }



    const onDragEnd = (result) => {
        if (!result.destination) return;

        const { source, destination } = result;
        if (source.index === destination.index) return;

        const updatedItems = Array.from(openingHours);
        const movedItem = updatedItems[source.index];
        updatedItems.splice(source.index, 1); // Remove item from original position
        updatedItems.splice(destination.index, 0, movedItem); // Insert item at new position

        setOpeningHours(updatedItems);
    };


    const deleteItem = (index) => {
        const items = [...openingHours];
        items.splice(index, 1);
        setOpeningHours(items);
    };




    const handleUpdateOpeningHours = async (e) => {



        try {
            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }

            let data;


            data = await request(`/openingHours/${id}/${currentUser}`, 'PUT', options, {

                contents: openingHours

            })

            fetchOpeningHours();

            alert("The OPENING HOURS have been successfully updated!")


        } catch (error) {
            console.error(error)
            alert("Your authentication token is expired. Please login again via the sign in page.");

        }

    }



    const handleEditCurrentContentString = (index, e) => {

        const newEntry = [...openingHours];


        newEntry[index].content = e.target.value;
        setOpeningHours(newEntry);



    }






    const [open, setOpen] = useState("")



    const handleOpen = (string) => {


        if (open === string) {
            setOpen("")
        } else {
            setOpen(string)
        }

    }





    const [seeTerms, setSeeTerms] = useState(false)



    const handleSeeTerms = () => {


        setSeeTerms(!seeTerms)
    }




    const [showFormPreview, setShowFormPreview] = useState(false)




    return (



        <>
            {seeTerms ? (<div className={classes.hoursWrapper}>


                <h2 onClick={() => { handleSeeTerms() }}>OPENING HOURS</h2>


                <button className={classes.uniButton} onClick={() => { setShowFormPreview(true) }}>See Preview</button>


                <div className={classes.keywordDiv}>

                    <h2>ADD ITEM</h2>



                    <div className={classes.typeSelection}>
                        <label>
                            Select Type:
                            <select className={classes.titleInput3} value={currentType} onChange={handleTypeChange}>
                                <option value="">Select an option</option> {/* Non-option */}
                                <option value="Area">Area</option>
                                <option value="Days">Days</option>
                                <option value="Times">Times</option>
                            </select>
                        </label>
                    </div>



                    <div className={classes.contentInputs}>
                        <input
                            className={classes.titleInput2}
                            type="text"
                            placeholder="Keyword..."
                            name="keyword"
                            value={currentWord || ""}
                            onChange={handleWord}
                        />
                    </div>









                    <div className={classes.plusButtonDiv}>
                        <IoMdAddCircle onClick={handleAddItem} className={classes.plusButton} />

                    </div>
                </div>




                <DragDropContext onDragEnd={onDragEnd} onBeforeDragStart={() => { setOpen("") }}>
                    <Droppable droppableId="menuItems">
                        {(provided) => (
                            <div className={classes.keywords2} ref={provided.innerRef} {...provided.droppableProps}>
                                {openingHours?.map((item, index) => (
                                    <Draggable key={index} draggableId={`item-${index}`} index={index}>
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                className={classes.keyword}
                                            >
                                                <AiOutlineClose onClick={() => {
                                                    deleteItem(index)
                                                }} className={classes.removeIcon} />

                                                <p className={classes.open} onClick={() => { handleOpen(`box-${index}`) }}>{item.elementType}</p>

                                                <div className={classes.hiddenBox} style={open === `box-${index}` ? { height: "auto", opacity: "1", zIndex: "999", maxHeight: "4000px", paddingTop: "2vh" } : { maxHeight: "0", zIndex: "-1", opacity: "0" }}>




                                                    <input
                                                        className={classes.titleInput2}
                                                        type="text"
                                                        placeholder="Content..."
                                                        value={item.content || ""}
                                                        onChange={(e) => handleEditCurrentContentString(index, e)}
                                                    />

                                                </div>


                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>

                <button className={classes.formButton2} onClick={handleUpdateOpeningHours}>Update Opening Hours</button>

                <Modal
                    isOpen={showFormPreview}
                    onRequestClose={() => setShowFormPreview(false)}
                    contentLabel="Preview Hours Modal"
                    className="modal"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: "999999999999999999999999999999999999",
                        },
                        content: {
                            width: '70%',
                            height: 'auto',
                            maxHeight: '80vh',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: "#f3c7c7",
                            border: "0",
                            borderRadius: "20px",
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch", // For iOS Safari
                            scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                            scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                        },
                    }}
                >
                    <div className={classes.menuContainer}>
                        <AiOutlineClose onClick={() => {
                            setShowFormPreview(false)
                        }} className={classes.removeIcon} />





                        <div className={classes.column}>





                            {openingHours?.map((object, index) => (

                                <>

                                    {object.elementType === "Area" && (

                                        <div className={classes.pDiv}>{object.content}</div>



                                    )

                                    }
                                    {object.elementType === "Days" && (

                                        <div className={classes.pDiv2}>{object.content}</div>



                                    )

                                    }

                                    {object.elementType === "Times" && (

                                        <div className={classes.pDiv}>{object.content}</div>


                                    )

                                    }
                                </>



                            ))}





                        </div>



                    </div>
                </Modal>



            </div >) : (


                <div className={classes.hoursWrapper}>

                    <h2 onClick={() => { handleSeeTerms() }}>OPENING HOURS</h2>



                </div>
            )


            }







        </>





    )
}

export default AdminOpeningHours