import React, { useState, useEffect } from 'react';
import 'react-tabs/style/react-tabs.css';
import styles from './menuTabs.module.css';

import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux'

const MenuTabs = () => {



  const menuData = useSelector((state) => state.menus.data);



  const [activeTabIndex, setActiveTabIndex] = useState(0);

  const handleTabSelect = (index) => {
    setActiveTabIndex(index);
  };

  const location = useLocation();


  useEffect(() => {
    // Check if the URL parameter indicates the festival tab
    const searchParams = new URLSearchParams(location.search);

    let i = 0;

    for (const menu of menuData) {


      if (searchParams.get(menu.route) !== null) {
        console.log("running")
        setActiveTabIndex(i); // Index of 'FESTIVAL MENU' in tabLabels array
        window.scrollBy(0, 1200);
      
       


      }

      i++;

    }

  



  }, [menuData]);


  const [tabLabels, setTabLabels] = useState([]);

  useEffect(() => {

    let labelArray = [];


    for (const menu of menuData) {

      labelArray.push(menu.route)

    }

    setTabLabels(labelArray)



  }, [menuData])


  useEffect(() => {

    console.log(menuData)


  }, [])

  // const tabLabels = ['BREAKFAST MENU', 'GASTRO MENU', 'UPSTAIRS MENU', "KID'S MENU", 'WINELIST', "SUNDAY LUNCH"];


  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  return (
    <div style={{ height: 'auto', width: '80%', paddingTop: '1.7vh', paddingBottom: '3vh' }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div className={styles.ul} >
          {tabLabels.map((label, index) => (
            <div key={index} className={styles.tabButton}>
              <button
                className={activeTabIndex === index ? styles.activeTabButton : ''}
                onClick={() => handleTabSelect(index)}
              >
                {label}
              </button>
            </div>
          ))}
        </div>
      </div>
      <div>



        <div className={styles.tabContent}>



          {menuData.length > 0 && menuData.map((menu, index) => (
            <>

              {activeTabIndex === index && (


                <div className={styles.container}>


                  <p className={styles.specialP}>{menu.title}</p>


                  {menu.contents.map((object, index) => (


                    <>
                      {object.elementType === "Title" && (
                        <div className={styles.specialP}>{object.content[0].contentString}</div>


                      )}



                      {object.elementType === "Dish" && (
                        <div className={styles.dish}>
                          <p className={styles.specialTitle}> {object.content[0].contentString}</p>
                          <p>{object.content[1].contentString}</p>
                          <span>{object.content[2].contentString}</span>
                        </div>

                      )}


                      {object.elementType === "WineItem" && (
                        <div className={styles.dish}>
                          <p className={styles.specialTitle}> {object.content[0].contentString}</p>
                          <p>{object.content[1].contentString}</p>
                          <span>{object.content[2].contentString}</span>
                        </div>

                      )}


                      {object.elementType === "Line" && (
                        <div className={styles.hr}>
                          <hr />


                        </div>

                      )}


                      {object.elementType === "Break" && (
                        <img className={styles.seperator} src={PF + "line.png"} alt="paragraph seperator" />

                      )}


                      {object.elementType === "CategoryTitle" && (
                        <p className={styles.categoryTitle}>{object.content[0].contentString}</p>

                      )}


                      {object.elementType === "Message" && (
                        <p className={styles.message}>{object.content[0].contentString}</p>


                      )}
                    </>




                  ))}





                </div>
              )}

            </>

          ))}


          {/* 

            <div className={styles.container}>
  <p className={styles.specialP}>DOWNSTAIRS @ CONNOLLY’S BREAKFAST - 9am to 12pm  - (MONDAY TO SUNDAY)</p>

  <div className={styles.dish}>
    <p className={styles.specialTitle}>The classic full Irish</p>
    <p> Using the Burren butchers bacon, sausages, poached organic local eggs,
      black pudding, crispy herb potato, grilled tomato,  chutney and toasted sourdough</p>
    <span>€14</span>
  </div>


  <div className={styles.dish}>
    <p className={styles.specialTitle}>Classic eggs benedict</p>
    <p>Poached organic local eggs on sourdough. <br /> With a choice of  thick cut bacon, spinach & hollandaise or samshed avocado, spinach & hollandaise </p>
    <span>€13</span>
  </div>






  <div className={styles.dish}>
    <p className={styles.specialTitle}>Oatmeal porridge </p>
    <p> Roasted pecan, Kinvara honey, cinnamon poached pear</p>
    <span>€8.5</span>

  </div>


  <div className={styles.dish}>
    <p className={styles.specialTitle}>Connolly’s house freshly baked sausage roll </p>

    <p>Served with apple and onion chutney </p>
    <span>€9</span>

  </div>
  <div className={styles.dish}>
    <p className={styles.specialTitle}>Connolly’s signature scone </p>

    <p>Served with raspberry jam and mascarpone cream</p>
    <span>€4.5</span>
    <p className={styles.message}>(please ask your server for today’s amazing flavour)</p>
  </div>
  <div className={styles.dish}>
    <p className={styles.specialTitle}>Poached Free Range Eggs on Toasted Sourdough</p>
    <span>€8</span>

  </div>


  <div className={styles.dish}>

    <div className={styles.specialTitle}>
      ADD:
    </div>

    <p className={styles.message}>2 Burren Butcher’s Sausages</p>
    <span>+2.5</span>
    <p className={styles.message}>1 Thick Cut Burren Butcher’s Bacon</p>
    <span>+2.5</span>
    <p className={styles.message}>2 Burren Butcher’s Black Pudding</p>
    <span>+2</span>
    <p className={styles.message}>Smashed Avocado</p>
    <span>+1.5</span>
    <p className={styles.message}>Crispy Herb Potatoes</p>
    <span>+2</span>

  </div>




</div>
            
            
            */ }

        </div>
      </div>
    </div>
  );
};

export default MenuTabs;


