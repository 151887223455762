import React, { useState } from 'react'
import classes from './signin.module.css'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { request } from '../../util/fetchAPI'
import { login } from '../../redux/authSlice'


const Signin = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  
const [email, setEmail] = useState("")
const [password, setPassword] = useState("")
const dispatch = useDispatch()
const navigate =  useNavigate()

const handleLogin = async(e) => {
  e.preventDefault();

  try {
    const options = {
      'Content-Type': 'application/json'
    }

    const data = await request('/auth/login', "POST" , options, {email, password})
    dispatch(login(data))
    navigate("/event-management")
    
  } catch (error) {
    console.error();
    alert("Check you have used the correct credentials")
  }

}

  return (
    <div style={{backgroundImage: `url('${ PF +  "U10.png"}')`}}  className={classes.container}>


      


      <div className={classes.wrapper}>
        <h2>Sign in</h2>
        <form onSubmit={handleLogin}>
          <input type="email" placeholder="Email..."  onChange={(e) => setEmail(e.target.value)}/>
          <input type="password" placeholder="Password..." onChange={(e) => setPassword(e.target.value)} />
          <button type="submit">Sign in</button>
          
        </form>
      </div>




    </div>
  )
}

export default Signin