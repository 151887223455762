import React, { useEffect, useRef } from 'react'
import styles from "./contactMain.module.css"
import { AiOutlineClose, AiFillPhone, AiOutlineMail } from 'react-icons/ai';
import MapWithMarker from '../map/Map';
import { useSelector } from 'react-redux'

const ContactMain = () => {

  const descRef = useRef([]);


  const reduxData = useSelector((state) => state.website);




  useEffect(() => {
    const appearOptions = {
      threshold: 0.02,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);

  const location = "Connolly's Pub, The Quay, Kinvara, Ireland"
  return (
    <div className={styles.container}>

      <div className={styles.wrapper} ref={(el) => (descRef.current[0] = el)}>

        <p className={styles.specialP}> {reduxData.data.contactDescTitle}</p>

        <div className={styles.hr}>
          <hr />
          <hr />
        </div>

        <p> {reduxData.data.contactDesc}</p>



        <div className={styles.icons}>

          <span>
            <AiFillPhone className={styles.icon} /> {reduxData.data.contactNumber}
          </span>
          <span id={styles.becauseSpecial}>
            <AiOutlineMail className={styles.icon} /> {reduxData.data.contactEmail}
          </span>

        </div>

        <MapWithMarker address={location} />



        <div className={styles.row}>

          <div className={styles.box}>

            <p className={styles.specialP}>{reduxData.data.contactMessageTitle1}</p>

            <p className={styles.parkingDetails}>{reduxData.data.contactMessageText1}</p>



          </div>


          <div className={styles.box}>
            <p className={styles.specialP}>{reduxData.data.contactMessageTitle2}</p>
            <p className={styles.parkingDetails}>{reduxData.data.contactMessageText2}</p>

          </div>
        </div>
      </div>
    </div>
  )
}

export default ContactMain