import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: []
 
 }



 export const menusSlice = createSlice({
    name: 'menus',
    initialState,
    reducers: {
        getMenusData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getMenusData} = menusSlice.actions;

export default menusSlice.reducer;



