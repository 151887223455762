import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: {}
 
 }



 export const termsSlice = createSlice({
    name: 'terms',
    initialState,
    reducers: {
        getTermsData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getTermsData} = termsSlice.actions;

export default termsSlice.reducer;



