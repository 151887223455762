import React from 'react'
import styles from "./tsAndCsMain.module.css"
import { useSelector } from 'react-redux'


const TsAndCsMain = () => {









  const termsData = useSelector((state) => state.terms.data)

    
  return (
    <div className={styles.container}>
        <div className={styles.wrapper}>
        <h1>TERMS & CONDITIONS</h1>



        <div className={styles.hr}>
          <hr />
          <hr />

        </div>

        {

          termsData.contents.map((object, index) => (


            <>




              {
                object.elementType === "Title" && (

                  <h2>{object.content[0].contentString}</h2>

                )


              }
              {
                object.elementType === "SubTitle" && (

                  <h3>{object.content[0].contentString}</h3>

                )

              }
              {
                object.elementType === "Paragraph" && (
                  <p>{object.content[0].contentString}</p>


                )

              }
              {
                object.elementType === "NumberedList" && (

                  <> {object.content.map((contentItem, index) => (

                    <h4>{index}. {contentItem.contentString}</h4>


                  ))

                  }
                  </>

                )

              }


              {object.elementType === "List" && (

                <ul> {object.content.map((contentItem, index) => (

                  <li>{contentItem.contentString}</li>


                ))

                }
                </ul>

              )}



            </>





          ))


        }





 
       
        </div>
    </div>
  )
}

export default TsAndCsMain;