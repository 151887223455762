import React, {useEffect, useRef, useState} from 'react'
import classes from "./musicMain.module.css";
import axios from 'axios';
import { request } from '../../util/fetchAPI';
import Slider from '../slider/Slider';
import Carousel from '../slider/Slider';
import { useSelector } from 'react-redux'



const MusicMain = (newEvents) => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const reduxData = useSelector((state) => state.website);
  const reduxMusic = useSelector((state) => state.music.data)


  
  /*useEffect(() => {
    const fetchAllEvents = async () => {
      const data = await request(`/event/getAll`, 'GET');
      setAllEvents(data);
    };
    fetchAllEvents();
  }, []); */






  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("visitkinvara@gmail.com");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()

  const [filteredEvents, setFilteredEvents] = useState([]);
  const [remainingEvents, setRemainingEvents] = useState([]);
  const [sortedEvents, setSortedEvents] = useState([]);






  const descRef = useRef([]);


  


  const formRef = useRef([]);


  const filterEvents = (events) => {

   
    let filteredEvents = [];
    let remainingEvents = [];





  
  
      

     const currentDate = new Date();

// Add 7 hours to the current date
const pastDate = new Date(currentDate.getTime() - 4 * 60 * 60 * 1000);


    
      events.forEach(event => {
        const eventDatetime = new Date(event.datetime);
       
    
        if (eventDatetime <= pastDate) {
          filteredEvents.push(event);
        } else {
          remainingEvents.push(event);
        }
      });
    
  
    setRemainingEvents(remainingEvents)
    setFilteredEvents(filteredEvents)
  
  }


  useEffect(() => {


    console.log(reduxMusic)

   if(reduxMusic.length > 0) {
    filterEvents(reduxMusic);


   }
     
    
  
     
   }, [reduxMusic]);


   useEffect(() => {
    console.log(filteredEvents)
     
   }, [filteredEvents])


    
  useEffect(() => {

   if(sortedEvents.length > 0){

    console.log(sortedEvents)
    const appearOptions = {
      threshold: 0.11,
    };
  
     const appearOnScroll = new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add(classes.appear);
            observer.unobserve(entry.target);
          }
        });
      }, appearOptions);
    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });
    formRef.current.forEach((form) => {
      appearOnScroll.observe(form);
    });
  

    return () => {
      appearOnScroll.disconnect();
    };

  }
  else {
    return;
  }


  



   


  }, [sortedEvents]);


  useEffect(() => {
   


    function sortEventsByDatetime(eventsArray) {
      const sortedEvents = eventsArray.slice().sort((eventA, eventB) => {
        const datetimeA = new Date(eventA.datetime);
        const datetimeB = new Date(eventB.datetime);
        
        return datetimeA - datetimeB;
      });
    
      return sortedEvents;
    }

    let sortedList  = sortEventsByDatetime(remainingEvents);

    setSortedEvents(sortedList)

    

   


  }, [remainingEvents]);






  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      try {
        await axios.post('https://connollysapi-n476.onrender.com/email/send', {
          recipient,
          subject,
          text,
          to,
        });
  
        // Clear the form fields after successful submission
        setRecipient('');
        setSubject('');
        setText('');

  
        alert('Email sent successfully!');
      } catch (error) {
        console.error('Error sending email:', error);
        alert('Failed to send email. Please try again later.');
        setSuccess(true)
        setTimeout(() => {
          setSuccess(false)
        }, 1100)
      }
    };



    function formatEventDatetime(datetimeString) {
      const eventDate = new Date(datetimeString);
    
      const options = {
        timeZone: 'Europe/Dublin',
        weekday: 'long',        // Display the full weekday name
        day: 'numeric',         // Display the day of the month
        month: 'long',         // Display the full month name
        year: 'numeric',        // Display the year
        hour: 'numeric',        // Display the hour in 12-hour format
        minute: 'numeric',      // Display the minutes
        hour12: true,           // Use 12-hour format
      };
    
      const formattedDatetime = eventDate.toLocaleString('en-US', options);
      return formattedDatetime;
    }


  return (
    <div className={classes.container}>

<div className={classes.middleDiv}>
<p className={classes.specialP}>{reduxData.data.musicDescTitle}</p>
  <div className={classes.hr}>
            <hr/>
            <hr/>

            </div>
  <p>{reduxData.data.musicDesc}</p>
                     

  
</div>
<div className={classes.eventsDiv}>
<p className={classes.specialP}>{reduxData.data.musicDescTitle2}</p>


{sortedEvents.length > 0  && reduxMusic.length > 0? (
  sortedEvents.map((event, index) => (
    <div key={event._id} className={classes.row} ref={(el) => (descRef.current[index] = el)}>
      <div className={classes.imgCont}>
        <img src={`https://connollysapi-n476.onrender.com/images/${event.img}`}  alt="opening" />
      </div>
      <div className={classes.textSection}>
        <p className={classes.specialP}>{event.artist}</p>
        <div className={classes.hr}>
          <hr />
          <hr />
        </div>
        <p className={classes.date}>{formatEventDatetime(event.datetime)}</p>
        
        <p className={classes.eventDesc}>
          
        {event.desc ? (
  `${event.desc.split('.').slice(0, 4).join('.')}${event.desc.split('.').length > 4 ? '...' : ''}`
) : (
  // Handle the case when event.desc is undefined or null
  'Description not available'
)}
</p>
       
      </div>
    </div>
  ))
) : (
  <h5 className={classes.message}>No events currently scheduled, please check back later.</h5>
)}



<p className={classes.specialP}>CONNOLLY'S EVENT ARCHIVE</p>

{filteredEvents.length > 0 && (<Carousel autoPlay={true} useRightLeftTriangles={true} slides={filteredEvents}/>)}


 

 




</div>

    
   {/*
  <div  className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (formRef.current[0] = el)}  onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>

      <p className={classes.mess}>For all music enquires, use the contact form below. </p>
      
      <form onSubmit={handleSubmit}>
      
        <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />

<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
       
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />

<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />


     
       
        <button className={classes.getBtn}>SEND</button>
      </form>
      
    </div>



</div> */}

  </div>
  )
}

export default MusicMain;