import { createSlice } from '@reduxjs/toolkit'

const initialState =  {

    data: [
        {
            _id: "6641dd3ebbced6400758c1e5",
            title: "Events @ Connolly's",
            topImg: "5881e2de-be90-47a6-bb78-41c074040390shoot (8).jpg",
            route: "events",
            introDesc: "Situated right on the harbour and at the very centre of Kinvara town,\n          Connolly's is a sublime venue and a perfect place for any special occasion. With its homely, yet stylish, decor -\n          its combination of gourmet fine-dining delicacies, boutique wine and old favourties -\n          we invite you to customise and work with the team @ Connolly's to craft your momentous day, tailored to your preferences.",
            introTitle: "The perfect host for your special occasion...",
            panels: [
                {
                    panelButtonType: "Book",
                    panelDesc: " Be it a party, a private gig or even a wedding reception, Connolly's takes bookings for all manner of celebrations.",
                    
                    panelImg: "fe0cdb5e-c8d1-4b69-a07a-2e344ea469bbwed14.jpeg",
                    panelTitle: "SEASONAL EVENTS @ CONNOLLY'S",
                    
                    _id: "6641dd3ebbced6400758c1e6"
                }
            ],
            createdAt: "2024-05-13T09:28:30.579Z",
            updatedAt: "2024-05-13T09:28:30.579Z",
            __v: 0
        }
    ]
 
 }



 export const routeSlice = createSlice({
    name: 'route',
    initialState,
    reducers: {
        getRouteData: (state, action) => {
            // Mutate the state to update it
            state.data = action.payload;
        },
      
    }
})

export const { getRouteData} = routeSlice.actions;

export default routeSlice.reducer;


