import React, { useState, useEffect } from 'react'
import classes from "./adminControl.module.css"
import { useDispatch, useSelector } from 'react-redux';
import { request } from '../../../util/fetchAPI'
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai'
import { register } from '../../../redux/authSlice'

const AdminControl = () => {

    const [showFormMusic, setShowFormMusic] = useState(false)
    const [showFormChef, setShowFormChef] = useState(false)
    const { user, token } = useSelector((state) => state.auth)
    const [state3, setState3] = useState({});
    const [stateChef, setStateChef] = useState({})
    const [photo3, setPhoto3] = useState("")
    const [newPassword, setNewPassword] = useState("")
   


    const handleNewPassword = (e) => {
        setNewPassword(e.target.value)
    }


    useEffect(() => {

        if (showFormMusic === false) {
            setPhoto3("")

        }


    }, [showFormMusic])


    useEffect(() => {

        if (showFormChef === false) {
            setPhoto3("")

        }

    }, [showFormChef])




    useEffect(() => {

        const options = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }

        const fetchMusicUser = async () => {
            const data = await request(`/auth/user/65f8c50afb9f1d8df33dacdd`, 'GET', options);
            setState3(data);
        };
        fetchMusicUser();

    }, []);


    useEffect(() => {

        const options = {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json'
        }

        const fetchChefUser = async () => {
            const data = await request(`/chefUser/user/664b98dbe5acf76c2820f842`, 'GET', options);
            setStateChef(data); 
        };
        fetchChefUser();

    }, []);




    const handleState3 = (e) => {
        setState3(prev => {
            return { ...prev, [e.target.name]: e.target.value }
        })

    }


    const handleStateChef = (e) => {
        setStateChef(prev => {
            return { ...prev, [e.target.name]: e.target.value }
        })

    }



    const handleSubmit = async (e) => {

        e.preventDefault()

        try {

            let filename = null
            if (photo3 !== undefined) {
                const formData = new FormData()
                filename = crypto.randomUUID() + photo3.name
                formData.append("filename", filename)
                formData.append('image', photo3)

                await request('/upload/image', "POST", {}, formData, true)
            } else {
                filename = state3.profileImg
            }

            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }


            if (newPassword !== undefined) {

                const data = await request(`/auth/user/${state3._id}/super`, "PUT", options, { ...state3, profileImg: filename, password: newPassword })
               
                console.log(data)
                setPhoto3("")

            } else {
                const data = await request(`/auth/user/${state3._id}/super`, "PUT", options, { ...state3, profileImg: filename })
                
                setPhoto3("")



            }



            alert("You have successfully updated the MUSIC Admin")




        } catch (error) {
            console.error(error)
            alert(error.msg)
        }
    }



    const handleSubmitChef = async (e) => {

        e.preventDefault()

        try {

            let filename = null
            if (photo3 !== undefined) {
                const formData = new FormData()
                filename = crypto.randomUUID() + photo3.name
                formData.append("filename", filename)
                formData.append('image', photo3)

                await request('/upload/image', "POST", {}, formData, true)
            } else {
                filename = stateChef.profileImg
            }

            const options = {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }


            if (newPassword !== undefined) {

                const data = await request(`/chefUser/user/${stateChef._id}/super`, "PUT", options, { ...stateChef, profileImg: filename, password: newPassword })
              

                console.log(data)
                setPhoto3("")

            } else {
                const data = await request(`/chefUser/user/${stateChef._id}/super`, "PUT", options, { ...stateChef, profileImg: filename })
              
                setPhoto3("")




            }



            alert("You have successfully updated the CHEF Admin")






        } catch (error) {
            console.error(error)
            alert(error.msg)
        }
    }





    return (
        <div className={classes.container}>

            <h2>Edit the Sub user accounts for the Connolly's Domain</h2>

            <p>The music account can be accessed here and the Chef account here...</p>

            <div className={classes.btnDiv}>

                <button className={classes.uniButton} onClick={() => { setShowFormMusic(true) }}>Edit Music User</button>
                <button className={classes.uniButton} onClick={() => { setShowFormChef(true) }}>Edit Chef User</button>

            </div>




            {showFormMusic && (


                <Modal
                    isOpen={showFormMusic}
                    onRequestClose={() => setShowFormMusic(false)}
                    contentLabel="Edit Music User Modal"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: "999999999999999999999999999999999999",
                        },
                        content: {
                            width: '60%',
                            height: '80vh',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: "#2d2c2c",
                            border: "0",
                            borderRadius: "20px",
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch", // For iOS Safari
                            scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                            scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                        },
                    }}
                >





                    <div className={classes.listPropertyForm}  >
                        <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                        <AiOutlineClose onClick={() => { setShowFormMusic(false) }} className={classes.removeIcon} />
                            <h2>Edit Music User</h2>
                            <form onSubmit={handleSubmit}>
                                <input type="text" name="username" value={state3.username} placeholder='Username' onChange={handleState3} required />
                                <input type="email" name="email" value={state3.email} placeholder='Email' onChange={handleState3} required />
                                <textarea value={state3.desc} className={classes.descrip} name="desc" placeholder='New description' onChange={handleState3} />
                                <input type="password" className={classes.newPass} placeholder='newPassword' onChange={handleNewPassword} />


                                <input id="photo3" type="file" style={{ display: 'none' }} onChange={(e) => setPhoto3(e.target.files[0])} />
                                <label className={classes.btnUpload} htmlFor="photo3">Upload photo </label>
                                <div>
                                    {photo3 ? <img className={classes.userImg} src={URL.createObjectURL(photo3)} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} /> :
                                        <img className={classes.userImg} src={`https://connollysapi-n476.onrender.com/images/${state3?.profileImg}`} style={{ width: "60px", height: "60px", borderRadius: "50%" }} alt="profile" />}
                                </div>

                                <p>Please enter the CURRENT PASSWORD for the account to make any changes to your profile, including creating a new password.</p>
                                <input type="password" name="currentPassword"  placeholder='currentPassword' onChange={handleState3} />

                                <button className={classes.formButton2} type="submit">UPDATE</button>

                            </form>
                            
                        </div>
                    </div>
                </Modal>)
            }
            {showFormChef && (


                <Modal
                    isOpen={showFormChef}
                    onRequestClose={() => setShowFormChef(false)}
                    contentLabel="Edit Chef User Modal"
                    style={{
                        overlay: {
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            zIndex: "999999999999999999999999999999999999",
                        },
                        content: {
                            width: '60%',
                            height: '80vh',
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            color: 'white',
                            backgroundColor: "#2d2c2c",
                            border: "0",
                            borderRadius: "20px",
                            boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.25)",
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch", // For iOS Safari
                            scrollbarWidth: "thin", // For modern browsers that support scrollbar customization
                            scrollbarColor: "rgba(255, 255, 255, 0.5) #2d2c2c", // For modern browsers that support scrollbar customization
                        },
                    }}
                >





                    <div className={classes.listPropertyForm}  >
                        <div className={classes.listPropertyWrapper} onClick={(e) => e.stopPropagation()}>
                        <AiOutlineClose onClick={() => { setShowFormChef(false) }} className={classes.removeIcon} />
                            <h2>Edit Chef User</h2>
                            <form onSubmit={handleSubmitChef}>
                                <input type="text" name="username" value={stateChef.username} placeholder='Username' onChange={handleStateChef} required />
                                <input type="email" name="email" value={stateChef.email} placeholder='Email' onChange={handleStateChef} required />
                                <textarea value={stateChef.desc} className={classes.descrip} name="desc" placeholder='New description' onChange={handleStateChef} />
                                <input type="password" className={classes.newPass} placeholder='newPassword' onChange={handleNewPassword} />


                                <input id="photo3" type="file" style={{ display: 'none' }} onChange={(e) => setPhoto3(e.target.files[0])} />
                                <label className={classes.btnUpload} htmlFor="photo3">Upload photo </label>
                                <div>
                                    {photo3 ? <img className={classes.userImg} src={URL.createObjectURL(photo3)} alt="" style={{ width: "60px", height: "60px", borderRadius: "50%" }} /> :
                                        <img className={classes.userImg} src={`https://connollysapi-n476.onrender.com/images/${stateChef?.profileImg}`} style={{ width: "60px", height: "60px", borderRadius: "50%" }} alt="profile" />}
                                </div>

                                <p>Please enter the CURRENT PASSWORD for the account to make any changes to your profile, including creating a new password.</p>
                                <input type="password" name="currentPassword"  placeholder='currentPassword' onChange={handleStateChef} />

                                <button className={classes.formButton2} type="submit">UPDATE</button>

                            </form>
                           
                        </div>
                    </div>
                </Modal>)
            }

        </div>
    )
}

export default AdminControl