import React, { useEffect, useRef } from 'react'
import styles from "./bookTableMain.module.css"
import ResDiaryWidget from '../widget/Widget';
import { useSelector } from 'react-redux'


const BookTableMain = () => {





  const descRef = useRef([]);
  const reduxData = useSelector((state) => state.website);


  useEffect(() => {
    const appearOptions = {
      threshold: 0.5,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(styles.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
    
        appearOnScroll.observe(desc);
        
 
      
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);



  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  return (
    <div className={styles.container}>

      <div className={styles.newCont}>
        <p className={styles.specialP}>{reduxData.data.bookTableTitle}</p>
        <div className={styles.hr}>
          <hr />
          <hr />

        </div>
        <p>{reduxData.data.bookTableDesc}</p>
        <p> <i> {reduxData.data.bookTableMessage} </i></p>



      </div>

      <div className={styles.wrapper} ref={(el) => (descRef.current[0] = el)}>

        <ResDiaryWidget />

      </div>

    </div>
  )
}

export default BookTableMain