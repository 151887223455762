import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux'
import classes from "./routeMain.module.css";
import axios from 'axios';
import { current } from '@reduxjs/toolkit';


const RouteMain = ({ currentId }) => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;


  const [success, setSuccess] = useState(false)
  const [recipient, setRecipient] = useState("chef@upstairsatconnollys.ie");
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const [to, setTo] = useState()
  const descRef = useRef([]);
  const imgRef = useRef([]);
  const boutonRef = useRef([]);
  const [currentRoute, setCurrentRoute] = useState({})

  const routeData = useSelector((state) => state.route.data)

  const [panels, setPanels] = useState([])





  const findRoute = async () => {

    for (const route of routeData) {
      if (route._id === currentId) {
        setCurrentRoute(route)

        setPanels(route.panels)
      }



    }


  }


  useEffect(() => {

    findRoute();

  }, [])







  useEffect(() => {


    if(panels.length > 0) {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    imgRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });

    boutonRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };

  }

  }, [panels]);










  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await axios.post('https://connollysapi-n476.onrender.com/email/send', {
        recipient,
        subject,
        text,
        to,
      });

      // Clear the form fields after successful submission
      setRecipient('');
      setSubject('');
      setText('');


      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again later.');

    }
  };



  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}> <span>{currentRoute.title}</span></div>
        <img src={`https://connollysapi-n476.onrender.com/images/${currentRoute.topImg}`} alt="A view of Connolly's restaurant" />

      </div>
      <div className={classes.middleDiv}>
        <p className={classes.specialP}>{currentRoute.introTitle}</p>
        <div className={classes.hr}>
          <hr />
          <hr />

        </div>
        <p className={classes.desc} id={classes.descId}>
          {currentRoute.introDesc}
        </p>

      </div>

      <div className={classes.wrapper}>



        {panels.map((panel, index) => (

          <>

            {index % 2 === 0 && (

              <div className={classes.row} ref={(el) => (descRef.current[index] = el)} >
                <div className={classes.imgCont}>
                  <img alt="festive" src={`https://connollysapi-n476.onrender.com/images/${panel.panelImg}`} />
                </div>
                <div className={classes.txtBox} >
                  <div className={classes.hr}>
                    <hr />
                  </div>
                  <p className={classes.specialP}>{panel.panelTitle}</p>
                  <p className={classes.desc}>
                    {panel.panelDesc}
                  </p>





                  {panel.panelButtonType === "Menu" && (
                    <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>SEE MENUS</button></a>

                  )


                  }

                  {panel.panelButtonType === "Gallery" && (

                    <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>

                  )


                  }

                  {panel.panelButtonType === "ContactUs" && (

                    <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>CONTACT US</button></a>

                  )


                  }

                  {panel.panelButtonType === "Book" && (

                    <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                  )


                  }

                  {panel.panelButtonType === "Experience" && (
                    <a className={classes.a} href="/chefs-table-experience"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                  )

                  }

                  {panel.panelButtonType === "Careers" && (
                    <a className={classes.a} href="/careers"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                  )


                  }

                  {panel.panelButtonType === "Music" && (
                    <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                  )


                  }

                  {panel.panelButtonType === "Wine" && (
                    <a className={classes.a} href="/wine-experience-room"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>


                  )

                  }




                  <div className={classes.hr}>
                    <hr />
                  </div>
                </div>
              </div>




            )


            }


            {

              index % 2 !== 0 && (


                <div className={classes.row}>
                  <div className={classes.imgCont} id={classes.idOne} ref={(el) => (imgRef.current[index] = el)} >
                    <img src={`https://connollysapi-n476.onrender.com/images/${panel.panelImg}`} />
                  </div>
                  <div className={classes.txtBox} id={classes.idTwo} ref={(el) => (boutonRef.current[index] = el)}>
                    <div className={classes.hr}>
                      <hr />
                    </div>
                    <p className={classes.specialP}>{panel.panelTitle}</p>
                    <p className={classes.desc}>
                    {panel.panelDesc}
                    </p>
                  

                  
                    {panel.panelButtonType === "Menu" && (
                    <a className={classes.a} href="/menus"> <button className={classes.giftBtn}>SEE MENUS</button></a>

                  )


                  }

                  {panel.panelButtonType === "Gallery" && (

                    <a className={classes.a} href="/gallery"> <button className={classes.giftBtn}>GALLERY</button></a>

                  )


                  }

                  {panel.panelButtonType === "ContactUs" && (

                    <a className={classes.a} href="/contact"> <button className={classes.giftBtn}>CONTACT US</button></a>

                  )


                  }

                  {panel.panelButtonType === "Book" && (

                    <a className={classes.a} href="/book-a-table"> <button className={classes.giftBtn}>BOOK A TABLE</button></a>

                  )


                  }

                  {panel.panelButtonType === "Experience" && (
                    <a className={classes.a} href="/chefs-table-experience"> <button className={classes.giftBtn}>CHEF'S TABLE</button></a>

                  )

                  }

                  {panel.panelButtonType === "Careers" && (
                    <a className={classes.a} href="/careers"> <button className={classes.giftBtn}>CAREERS</button></a>

                  )


                  }

                  {panel.panelButtonType === "Music" && (
                    <a className={classes.a} href="/music-at-connollys"> <button className={classes.giftBtn}>WHAT'S ON?</button></a>

                  )


                  }

                  {panel.panelButtonType === "Wine" && (
                    <a className={classes.a} href="/wine-experience-room"> <button className={classes.giftBtn}>WINE CAVE</button></a>


                  )

                  }
                  



                    <div className={classes.hr}>
                      <hr />
                    </div>

                  </div>
                </div>




              )


            }





          </>

        ))}







    






      </div>



      {/* <div style={{backgroundImage: `url('${ PF +  "beauty.webp"}')`}} className={classes.contactForm}>
    <div className={classes.contactFormWrapper} ref={(el) => (descRef.current[1] = el)} onClick={(e) => e.stopPropagation()}>
      <p className={classes.specialP}>GET IN TOUCH</p>
      <p className={classes.mess}>For all career inquiries, contact Jason O'Neill for more details. </p>
      <form onSubmit={handleSubmit}>
      <input  type="email"
        placeholder='My email' 
            value={recipient}
            onChange={(e) => setRecipient(e.target.value)}
            style={{display: "none"}}
            required  />
<input  type="text"
        placeholder='My email'
        value={to}
        onChange={(e) => setTo(e.target.value)}
        required />
        <input  type="text"
        placeholder='Subject' 
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required />
<textarea value={text}
        placeholder='My message' 
            onChange={(e) => setText(e.target.value)}
            required />
      <button className={classes.getBtn}>SEND</button>
    </form>
  </div> 
  </div>*/}
    </div>

  )
}

export default RouteMain;