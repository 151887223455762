import React from 'react'
import styles from "./musicVideo.module.css"
import BackgroundVideo from '../backgroundVideo/BackgroundVideo'
import { useSelector } from 'react-redux'

const MusicVideo = () => {
  const PF = process.env.REACT_APP_PUBLIC_FOLDER;
  const reduxData = useSelector((state) => state.website);


  return (
    <div  className={styles.carouselCont}>
  
   <img className={styles.img} src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.musicTopImg}` }/> 
   {/*<BackgroundVideo  video={"https://upstairsatconnollys.ie/images/AVid.mp4"}/> */}

   <div className={styles.homeAbso}>
    <p className={styles.homeSpecialP}>{reduxData.data.musicTitle}</p>
  </div>  
  </div>
  )
}

export default MusicVideo