import React, { useRef, useEffect } from 'react';
import classes from "./wineExperienceMain.module.css";
import { useSelector } from 'react-redux'



const WineMain = () => {

  const PF = process.env.REACT_APP_PUBLIC_FOLDER;

  const reduxData = useSelector((state) => state.website);



  const descRef = useRef([]);

  useEffect(() => {
    const appearOptions = {
      threshold: 0.11,
    };

    const appearOnScroll = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add(classes.appear);
          observer.unobserve(entry.target);
        }
      });
    }, appearOptions);

    descRef.current.forEach((desc) => {
      appearOnScroll.observe(desc);
    });




    return () => {
      appearOnScroll.disconnect();
    };
  }, []);










  return (

    <div className={classes.container}>
      <div className={classes.topDiv}>
        <div className={classes.abso}>
          <span>{reduxData.data.wineCaveTitle}</span>

        </div>
        <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.wineCaveTopImg}`} alt="" />

      </div>
      <div className={classes.middleDiv}>
        <p className={classes.specialP}>{reduxData.data.wineCaveDescTitle}</p>
        <div className={classes.hr}>
          <hr />
          <hr />

        </div>
        <p>{reduxData.data.wineCaveDesc}</p>


      </div>

      <div className={classes.photosDiv} ref={(el) => (descRef.current[0] = el)}>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.wineCaveImg1}`} />
        </div>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.wineCaveImg2}`} />
        </div>
        <div className={classes.picDiv}>
          <img src={`https://connollysapi-n476.onrender.com/images/${reduxData.data.wineCaveImg3}`} />
        </div>
      </div>




    </div>

  )
}

export default WineMain;