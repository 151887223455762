import React from 'react'

import Navbar2 from '../../components/navbar2/navbar2'
import ColumnHero from '../../components/columnHero/ColumnHero'

import DisclaimerRow from '../../components/disclaimerRow/DisclaimerRow'

import BookTableMain from '../../components/bookTableMain/BookTableMain'
import TopComponent from '../../components/topComponent/TopComponent'

const BookTable = () => {

  const  navStyles={  position: "relative"}


  return (
    <div className="homeContainer">
   
   <TopComponent/>
    <Navbar2 styles2={navStyles}/>
   <BookTableMain/>
    <ColumnHero/>
   
    <DisclaimerRow/>
    
    
    </div>
  )
}

export default BookTable